import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import firebase from 'firebase/app';
import fire from '../../fire';
import { setCurrentDevice } from '../../config/actions';
import colors from '../../constants/colors';
import CMButton from './CMButton';
import CMAlert from './CMAlert';

const StyledDialog = styled(Dialog)`
    .container {
        padding: 15px;

        .title {
            text-align: center;
            padding: 12px 0 24px;
            font-weight: 600;
            user-select: none;
            color: ${colors.greys.darkest};
        }

        .field-container {
            padding-bottom: 12px;

            .label {
                font-size: 0.9em;
                padding-bottom: 2px;
                font-weight: 600;
                color: ${colors.greys.darkest};
                user-select: none;
            }

            .input-field {
                border-radius: 2px;
                border: 1px solid grey;
                font-size: 1em;
                padding: 4px;
                font-family: "Montserrat";
            }
        }

        .button-container {
            text-align: center;
        }
    }
`;

const AddDeviceModal = ({ open, handleClose }) => {
    const [deviceID, setDeviceID] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const dispatch = useDispatch();

    const addDeviceToAccount = async () => {
        if (!(deviceID && deviceName)) {
            setAlertMessage('Please enter both fields');
            setOpenAlert(true);
        } else {
            const deviceIDUpper = deviceID.toLocaleUpperCase();

            const db = fire.firestore();
            const { uid: userUID } = fire.auth().currentUser;

            const deviceRef = db.collection('devices').doc(deviceIDUpper);
            const userRef = db.collection('users').doc(userUID);

            setLoading(true);

            await db.runTransaction(async transaction => {
                await transaction.get(deviceRef).then((deviceDoc) => {
                    if (!deviceDoc.exists || deviceDoc.data().owner) {
                        throw new Error("Unable to add. Please check the Canopy Mate ID you entered.");
                    }
                    transaction.update(userRef, {
                        devices: firebase.firestore.FieldValue.arrayUnion(deviceIDUpper)
                    })
                    .update(deviceRef, {
                        owner: userUID, 
                        name: deviceName
                    })
                })
            })
            .then(() => {
                setLoading(false);
                dispatch(setCurrentDevice(deviceIDUpper));
                setDeviceName('');
                setDeviceID('');
                handleClose();
            })
            .catch(error => {
                setLoading(false);
                setOpenAlert(true);
                setAlertMessage(error.message);
            });
        }
    }

    return (
        <StyledDialog open={open} onClose={handleClose}>
            <div className="container">
                <div className="title">
                    Add Canopy Mate
                </div>
                <div className="field-container">
                    <div className="label">Canopy Mate ID:</div>
                    <input 
                        className="input-field" 
                        type="text" 
                        value={deviceID}
                        autoFocus={true}
                        onChange={e => setDeviceID(e.target.value)}
                    />
                </div>
                <div className="field-container">
                    <div className="label">Name:</div>
                    <input 
                        className="input-field" 
                        type="text" 
                        value={deviceName}
                        onChange={e => setDeviceName(e.target.value)}
                    />
                </div>
                <div className="button-container">
                    <CMButton
                        text="Add"
                        onClick={addDeviceToAccount}
                        loading={loading}
                        customStyle={{ marginTop: 18 }}
                    />
                </div>
            </div>
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
        </StyledDialog>
    );
};

export default AddDeviceModal;
