import { CURRENT_DEVICE } from '../constants/storageKeys';

export const ACTIONS = {
    ADD_DEVICE: 'ADD_DEVICE',
    REMOVE_DEVICE: 'REMOVE_DEVICE',
    SET_CURRENT_DEVICE: 'SET_CURRENT_DEVICE',
    DISABLE_PERIPHERAL_ACCESS: 'DISABLE_PERIPHERAL_ACCESS',
    ENABLE_PERIPHERAL_ACCESS: 'ENABLE_PERIPHERAL_ACCESS',
    WRITE_LAST_HEARTBEAT: 'WRITE_LAST_HEARTBEAT',
    REMOVE_CURRENT_GROW: 'REMOVE_CURRENT_GROW',
    ADD_GROW: 'ADD_GROW',
    REMOVE_GROW: 'REMOVE_GROW',
    SHOW_CLIENT_CONNECTION_ALERT: 'SHOW_CLIENT_CONNECTION_ALERT',
    CLOSE_CLIENT_CONNECTION_ALERT: 'CLOSE_CLIENT_CONNECTION_ALERT',
    SHOW_CANOPY_MATE_CONNECTION_ALERT: 'SHOW_CANOPY_MATE_CONNECTION_ALERT',
    CLOSE_CANOPY_MATE_CONNECTION_ALERT: 'CLOSE_CANOPY_MATE_CONNECTION_ALERT'
}

// Device

export const addDevice = (deviceID, deviceConfig) => ({
    type: ACTIONS.ADD_DEVICE,
    payload: { deviceID, deviceConfig }
});

export const removeDevice = deviceID => {
    return {
        type: ACTIONS.REMOVE_DEVICE,
        deviceID
    };
};

export const setCurrentDevice = deviceID => {
    localStorage.setItem(CURRENT_DEVICE, deviceID);
    return {
        type: ACTIONS.SET_CURRENT_DEVICE,
        deviceID
    };
};

// Access

export const disablePeripheralAccess = () => {
    return {
        type: ACTIONS.DISABLE_PERIPHERAL_ACCESS
    };
};

export const enablePeripheralAccess = () => {
    return {
        type: ACTIONS.ENABLE_PERIPHERAL_ACCESS
    };
};

export const showClientConnectionAlert = () => {
    return {
        type: ACTIONS.SHOW_CLIENT_CONNECTION_ALERT
    };
};

export const closeClientConnectionAlert = () => {
    return {
        type: ACTIONS.CLOSE_CLIENT_CONNECTION_ALERT
    };
};

export const showCanopyMateConnectionAlert = () => {
    return {
        type: ACTIONS.SHOW_CANOPY_MATE_CONNECTION_ALERT
    }
};

export const closeCanopyMateConnectionAlert = () => {
    return {
        type: ACTIONS.CLOSE_CANOPY_MATE_CONNECTION_ALERT
    }
};

// Connection

export const writeLastHeartBeat = (deviceID, seconds) => {
    return {
        type: ACTIONS.WRITE_LAST_HEARTBEAT,
        payload: { deviceID, seconds }
    }
}

// Grows

export const addGrow = (growID, growData, category) => {
    return {
        type: ACTIONS.ADD_GROW,
        payload: { growID, growData, category }
    }
}

export const removeGrow = (growID, category) => {
    return {
        type: ACTIONS.REMOVE_GROW,
        payload: { growID, category }
    }
}
