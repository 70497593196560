import fire from './fire';

const logAnalyticsEvent = (event, params) => {
    if (process.env.NODE_ENV === 'production') {
        fire.analytics().logEvent(event, {
            user: fire.auth().currentUser.uid,
            date: new Date(),
            ...params
        });
    }
};

export default logAnalyticsEvent;
