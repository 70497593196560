import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import updatePeripheral from './update';
import colors, { homePageControl } from '../../constants/colors';
import styles from '../../constants/styles';

const SwitchContainer = styled.div`
    .label {
        color: ${colors.homePageLabel};
        font-weight: ${styles.homePageLabelFontWeight};
    }

    .MuiSwitch-colorPrimary.Mui-checked {
        color: ${homePageControl};
    }

    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: ${homePageControl};
    }

    .MuiSwitch-colorPrimary {
        &.Mui-checked:hover, &:hover {
            background-color: unset;
        }
    }
`;

const FanSwitch = ({ fanState, label, field }) => {

    const currentDevice = useSelector(state => state.devices.currentDevice);
    const peripherals = useSelector(state => state.access.peripherals);

    return (
        <SwitchContainer>
            <span className="label">
                {label}:
            </span>
            <Switch
                disabled={!peripherals}
                checked={fanState}
                color="primary"
                onChange={async () => await updatePeripheral(currentDevice, field, !fanState)}
            />
        </SwitchContainer>
    );
};

export default FanSwitch;
