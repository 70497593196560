import fire from '../../fire';
import { store } from '../../index';
import { 
    disablePeripheralAccess, 
    enablePeripheralAccess, 
    showClientConnectionAlert, 
    showCanopyMateConnectionAlert,
    addDevice
} from '../../config/actions';
import Axios from 'axios';
import logAnalyticsEvent from '../../logAnalyticsEvent';
import { APP_ENGINE_URL } from '../../constants/formats';
import CryptoJS from 'crypto-js';

const updatePeripheral = async (currentDevice, peripheral, value) => {
    store.dispatch(disablePeripheralAccess());

    // Copy redux state and update with peripheral change before passing to cloud command
    const deviceData = Object.assign({}, store.getState().devices[currentDevice]);
    const oldValue = deviceData[peripheral];
    deviceData[peripheral] = value;

    const encryptedDeviceData = CryptoJS.AES.encrypt(JSON.stringify(deviceData), 'c4nopym4t3').toString();

    return Axios.post(APP_ENGINE_URL + "/update2", { encryptedDeviceData }).then((response) => {
        if (response.data.configUpdated) {
            // update redux before device listener receives update so UI has cleaner reflection of device state
            store.dispatch(addDevice(currentDevice, deviceData));
            store.dispatch(enablePeripheralAccess());
            logAnalyticsEvent('configUpdated', {
                peripheral,
                oldValue,
                newValue: value
            })
            return true;
        } else {
            store.dispatch(showCanopyMateConnectionAlert());
            store.dispatch(enablePeripheralAccess());
            return false;
        }
    })
    .catch(() => {
        store.dispatch(enablePeripheralAccess());
        store.dispatch(showClientConnectionAlert());
        return false;
    });
};

export const turnOffAllPeripherals = async (currentDevice) => {
    const db = fire.firestore();
    const deviceRef = db.collection('devices').doc(currentDevice);

    const deviceData = Object.assign({}, store.getState().devices[currentDevice]);

    // In case this is lingering in the store after the end grow transaction runs
    // it could result in a race condition where the currentGrow pointer gets incorrectly reset
    if (deviceData["currentGrow"]) {
        delete deviceData["currentGrow"];
    }

    deviceData["fan_state"] = false;
    deviceData["fan2_state"] = false;
    deviceData["light_duration"] = 0;
    deviceData["pump_on_duration"] = 0;

    const encryptedDeviceData = CryptoJS.AES.encrypt(JSON.stringify(deviceData), 'c4nopym4t3').toString();

    return Axios.post(APP_ENGINE_URL + "/update2", { encryptedDeviceData }).then((response) => {
        if (response.data.configUpdated) {
            deviceRef.update(deviceData);
        }
    });
}

export default updatePeripheral;
