import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../common/Header';
import colors, { appBackground, cardBackground, growTabColor } from '../../constants/colors';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Current from './Current';
import Past from './Past';

const Container = styled.div`
    min-height: 100vh;
    background-color: ${appBackground};

    .grow-card-container {
        display: flex;
        justify-content: center;

        .grow-card {
            width: calc(80% + 24px);
            max-width: 350px;
            margin-top: 1em;
            background-color: ${cardBackground};

            .MuiTabs-root {
                .MuiTab-root {
                    font-family: "Montserrat";
                    text-transform: unset;
                    font-weight: 600;
                    font-size: 1.1em;
                }

                .MuiTab-textColorPrimary {
                    color: ${colors.greys.strong};

                    &.Mui-selected {
                        color: ${growTabColor};
                    }
                }

                .MuiTabs-indicator {
                    background-color: ${growTabColor};
                }
            }
        }
    }
`;

const Grows = () => {
    const [growTab, setGrowTab] = useState(0);
    
    return (
        <>
            <Container>
                <Header />
                <div className="grow-card-container">
                    <Card className="grow-card">
                        <Tabs
                            value={growTab}
                            onChange={(e, tab) => setGrowTab(tab)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Current" />
                            <Tab label="Past" />
                        </Tabs>
                        {growTab === 0 && <Current
                            switchToPastTab={() => setGrowTab(1)}
                        />}
                        {growTab === 1 && <Past />}
                    </Card>
                </div>
            </Container>
        </>
    );
}

export default Grows;
