import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setCurrentDevice } from '../../config/actions';
import AddDeviceModal from './AddDeviceModal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CMButton from './CMButton';

const StyledContainer = styled.div`
    .MuiMenuItem-root {
        font-family: "Montserrat";
        font-weight: 600;
    }
`;

const DeviceSelect = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);

    const devices = useSelector(state => state.devices);

    const dispatch = useDispatch();

    const handleClick = (event, deviceCount) => {
        // if (deviceCount === 1) return null;
        setAnchorEl(event.currentTarget);
    }

    const handleDeviceSelect = deviceID => {
        dispatch(setCurrentDevice(deviceID));
        setAnchorEl(null);
    };

    const { currentDevice, ...usersDevices } = devices;

    const deviceCount = Object.keys(usersDevices).length;

    const userOwnsDevice = deviceCount > 0;

    return (
        <StyledContainer>
            {(!userOwnsDevice) &&
                <>
                    <CMButton
                        text="Add Canopy Mate"
                        onClick={() => setOpenAddDeviceModal(true)}
                    />
                    <AddDeviceModal open={openAddDeviceModal} handleClose={() => setOpenAddDeviceModal(false)} />
                </>
            }
            {(userOwnsDevice) &&
                <>
                    <CMButton
                        onClick={e => handleClick(e, deviceCount)}
                        customStyle={{ margin: '6px 0 15px' }}
                    >
                        {usersDevices[currentDevice] && usersDevices[currentDevice].name}
                        {/* {(deviceCount > 1) && <ArrowDropDownIcon />} */}
                        <ArrowDropDownIcon />
                    </CMButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        {Object.keys(usersDevices).map((device, idx) => (
                            <MenuItem 
                                key={idx} 
                                onClick={() => handleDeviceSelect(device)}
                            >
                                {usersDevices[device].name}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }
        </StyledContainer>
    );
};

export default DeviceSelect;
