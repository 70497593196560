import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from '../../constants/formats';
import colors, { homePageControl } from '../../constants/colors';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import fire from '../../fire';
import CMButton from '../common/CMButton';
import logAnalyticsEvent from '../../logAnalyticsEvent';

const PickerContainer = styled.div`
    .MuiFormControl-root {
        display: none;
    }

    .label {
        color: ${colors.greys.dark};
        font-weight: 600;
        padding-bottom: 6px;
    }
`;

const customPickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: homePageControl
        }
    },
});

const StartDatePicker = ({ growID, field, growStartDate, label, buttonWidth }) => {
    const [startDate, setStartDate] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setStartDate(moment(growStartDate, MOMENT_DATE_FORMAT));
    }, [growStartDate]);

    const updateStartDate = date => {
        const startDateString = moment(date).format(MOMENT_DATE_FORMAT);
        fire.firestore().collection('grows').doc(growID).update({
            [field]: startDateString
        });
        logAnalyticsEvent('startDateChange', { field });
    };

    return (
        <PickerContainer>
            <div className="label">
                {label}
            </div>
            <CMButton
                text={moment(startDate).format("MMMM Do")}
                onClick={() => setOpen(true)}
                customStyle={{
                    margin: 0,
                    minWidth: buttonWidth
                }}
            />
            <ThemeProvider theme={customPickerTheme}>
                <DatePicker
                    showTodayButton
                    disableFuture
                    value={startDate}
                    open={open}
                    onClose={() => setOpen(false)}
                    onChange={date => {
                        setStartDate(date);
                        updateStartDate(date);
                    }}
                />
            </ThemeProvider>
        </PickerContainer>
    );
};

export default StartDatePicker;
