import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import updatePeripheral from './update';
import colors, { homePageControl } from '../../constants/colors';
import styles from '../../constants/styles';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import CMButton from '../common/CMButton';

const PickerContainer = styled.div`
    padding-bottom: 12px;

    .MuiFormControl-root {
        display: none;
    }

    .time-on-control-container {
        ${'' /* width: 210px; */}
        text-align: center;

        .label {
            color: ${colors.homePageLabel};
            font-weight: ${styles.homePageLabelFontWeight};
            padding: 12px 0 6px 0;
        }
    }
`;

const customPickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: homePageControl
        }
    },
});

const TimeOnPicker = ({ secondsIntoUtcDay, label, field }) => {

    const [time, setTime] = useState(null);
    const [open, setOpen] = useState(false);

    const timeRef = useRef();

    const currentDevice = useSelector(state => state.devices.currentDevice);
    const peripherals = useSelector(state => state.access.peripherals);

    // Need to consider half offset zones ei. Newfoundland
    const minutesOffset = moment().utcOffset();

    useEffect(() => {
        const hours = Math.floor(secondsIntoUtcDay / 3600);
        const minutes = Math.round((secondsIntoUtcDay % 3600) / 60)

        const timePoint = moment.utc(`${hours}:${minutes}`, "HH:mm").utcOffset(minutesOffset);

        timeRef.current = timePoint;
        setTime(timePoint);

    }, [secondsIntoUtcDay, minutesOffset]);

    const updateTimeValue = async (timeMoment) => {
        const timeString = moment(timeMoment).format('HH:mm');

        let [hours, minutes] = timeString.split(':');
        hours = Number(hours);
        minutes = Number(minutes);

        // Need to consider half offset zones ei. Newfoundland
        const hoursOffset = minutesOffset / 60;
        // Cheat the time forward by 24 to avoid positive offset modulus errors
        const hoursUTC = (hours + 24 - hoursOffset) % 24;

        const secondsUTC = (hoursUTC * 3600) + (minutes * 60);

        const result = await updatePeripheral(currentDevice, field, secondsUTC);

        if (result) {
            setTime(timeMoment);
        } else {
            setTime(timeRef.current);
        }
    }

    return (
        <PickerContainer>
            <div className="time-on-control-container">
                <div className="label">
                    {label}
                </div>
                <CMButton
                    text={moment(time).format("h:mm A")}
                    onClick={() => setOpen(true)}
                    customStyle={{
                        margin: 0
                    }}
                />
            </div>
            <ThemeProvider theme={customPickerTheme}>
                <TimePicker 
                    disabled={!peripherals}
                    value={time}
                    open={open}
                    onClose={() => setOpen(false)}
                    onChange={timeMoment => {
                        updateTimeValue(timeMoment)
                    }}
                />
            </ThemeProvider>
        </PickerContainer>
    )
}

export default TimeOnPicker;
