import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import updatePeripheral from './update';
import colors, { homePageControl } from '../../constants/colors';
import styles from '../../constants/styles';
import ConfirmAction from '../common/ConfirmAction';
import { MAX_LIGHT_HOURS_FLOWERING } from '../../constants/formats';
import moment from 'moment';

const SliderContainer = styled.div`
    width: 80%;
    max-width: 210px;
    padding-top: 12px;
    text-align: center;

    .label {
        color: ${colors.homePageLabel};
        font-weight: ${styles.homePageLabelFontWeight};
    }

    .MuiSlider-root {
        color: ${homePageControl};
    }
`;

const DurationSlider = ({ label, field, durationSeconds, step, min, max, stepSeconds, hasGrowFlowering, pumpOnSlider, pumpOffSlider }) => {

    const [value, setValue] = useState(null);
    const [openConfirmLight, setOpenConfirmLight] = useState(false);
    const [openConfirmOnSlider, setOpenConfirmOnSlider] = useState(false);
    const [openConfirmOffSlider, setOpenConfirmOffSlider] = useState(false);
    const [tempValue, setTempValue] = useState(0);

    const previousValue = useRef();

    const currentDevice = useSelector(state => state.devices.currentDevice);
    const peripherals = useSelector(state => state.access.peripherals);

    const devices = useSelector(state => state.devices);

    useEffect(() => {
        const pointValue = durationSeconds / stepSeconds;
        previousValue.current = pointValue;
        setValue(pointValue);
    }, [durationSeconds, stepSeconds]);

    const updateDurationValue = async (v) => {
        const newDurationSeconds = v * stepSeconds;
        const result = await updatePeripheral(currentDevice, field, newDurationSeconds);
        if (!result) {
            setValue(previousValue.current);
        }
    };

    const confirmFloweringLightChange = v => {
        if (v > MAX_LIGHT_HOURS_FLOWERING) {
            setTempValue(v);
            setOpenConfirmLight(true);
        } else {
            updateDurationValue(v);
        }
    }

    const pumpWillRun = offDuration => {
        const lastRunEndTime = devices[currentDevice].pump_last_run_end_time;
        const currentTime = moment().unix();

        const nextRunTime = lastRunEndTime + offDuration;

        return currentTime >= nextRunTime;
    }

    const confirmOnSlider = v => {
        // Only need to confirm when turning schedule on
        if (previousValue.current === 0) {
            if (v > 0) {
                const offDuration = devices[currentDevice].pump_off_duration;
                
                if (pumpWillRun(offDuration)) {
                    setTempValue(v);
                    setOpenConfirmOnSlider(true);
                } else {
                    updateDurationValue(v);
                }
            }
        } else {
            updateDurationValue(v);
        }
    }

    const confirmOffSlider = v => {
        const offDuration = v * stepSeconds;
        if (pumpWillRun(offDuration)) {
            setTempValue(v);
            setOpenConfirmOffSlider(true);
        } else {
            updateDurationValue(v);
        }
    }

    return (
        <SliderContainer>
            <div className="label">
                {label}
            </div>
            <Slider
                value={value}
                valueLabelDisplay="auto"
                disabled={!peripherals}
                step={step}
                marks
                min={min}
                max={max}
                onChange={(e, v) => setValue(v)}
                onChangeCommitted={(e, v) => {
                    if (hasGrowFlowering) {
                        confirmFloweringLightChange(v);
                    } else if (pumpOnSlider) {
                        confirmOnSlider(v);
                    } else if (pumpOffSlider) {
                        confirmOffSlider(v);
                    } else {
                        updateDurationValue(v);
                    }
                }}
            />
            <ConfirmAction
                open={openConfirmLight}
                onClose={() => {
                    setValue(previousValue.current);
                    setOpenConfirmLight(false);
                }}
                subMessage="You currently have a grow that is flowering. Setting the LED on for more than 12 hours a day can potentially ruin your yield!"
                onConfirm={() => {
                    setOpenConfirmLight(false);
                    updateDurationValue(tempValue);
                }}
            />
            <ConfirmAction
                open={openConfirmOnSlider}
                onClose={() => {
                    setOpenConfirmOnSlider(false);
                    setValue(previousValue.current);
                }}
                confirmMessage="Ready to run?"
                subMessage={`Your pump will run for ${value} seconds if you set it on`}
                onConfirm={() => {
                    setOpenConfirmOnSlider(false);
                    updateDurationValue(tempValue);
                }}
            />
            <ConfirmAction
                open={openConfirmOffSlider}
                onClose={() => {
                    setOpenConfirmOffSlider(false);
                    setTimeout(() => {
                        setValue(previousValue.current);
                    }, 200)
                }}
                subMessage={`Reducing the off time to ${value} hour${value > 1 ? 's' : ''} will run the pump right away`}
                onConfirm={() => {
                    setOpenConfirmOffSlider(false);
                    updateDurationValue(tempValue);
                }}
            />
        </SliderContainer>
    );
};

export default DurationSlider;
