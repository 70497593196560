import { createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import reducer from './reducer';

const configureStore = () => {
    const store = createStore(reducer, {}, devToolsEnhancer());

    return store;
}

export default configureStore;
