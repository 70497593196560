const colors = {
    black: '#292f36',
    white: '#f7fff7',
    grey: '#7b7582',
    homePageLabel: 'rgba(0, 0, 0, 0.54)',
    blues: {
        light: '#B1D6F4',
        primary: '#54A6E7',
        strong: '#4588BE',
        dark: '#366A93',
        darkest: '#1F3D54'
    },
    greens: {
        light: '#92F9C0',
        primary: '#42C67B',
        strong: '#37A365',
        dark: '#2A7F4F',
        darkest: '#18482D'
    },
    greys: {
        light: '#EAEAEA',
        primary: '#C2C2C2',
        strong: '#9A9A9A',
        dark: '#727272',
        darkest: '#4A4A4A',
        nearBlack: '#222222'
    },
    testGreen: {
        light: '#9DFACF',
        // primary: '#59CD90', // 1
        primary: 'hsla(148, 62%, 50%, 1)',
        // primary: 'hsla(148, 100%, 40%, 1)',
        strong: '#47A973',
        darkest: '#023408'
    }
}

const mainThemeColor = 'testGreen';

export const appBackground = colors.blues.light;
export const appHeader = colors[mainThemeColor].primary;
export const buttonBackground = colors[mainThemeColor].primary;
export const buttonHover = colors[mainThemeColor].strong;
export const growTabColor = colors[mainThemeColor].strong;
export const spinnerColor = colors[mainThemeColor].strong;
export const homePageControl = colors[mainThemeColor].primary;
export const buttonTextColor = colors.white;
export const hamburger = colors.white;
export const journalTheme = colors.blues.primary;
export const journalThemeSpinner = colors.blues.darkest;

export const connectionColors = {
    green: '#82fc00',
    red: '#ff6b6b',
    grey: '#939393'
}

export const cardBackground = '#fafafa';

export const trashCanRed = 'hsla(0, 70%, 65%, 1)';

export default colors;
