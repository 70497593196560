import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { buttonBackground, buttonHover, buttonTextColor } from '../../constants/colors';
import Spinner from './Spinner';

const StyledButton = styled(Button)`
    && {
        margin: 12px;
        text-transform: unset;
        font-family: "Montserrat";
        font-size: 0.9em;
        padding: 4px 8px;
        color: ${buttonTextColor};
        letter-spacing: 0.03em;
        font-weight: 600;
        background-color: ${buttonBackground};
        position: relative;
    }

    &.MuiButton-contained:hover {
        background-color: ${buttonHover};
    }

    .loading-spinner {
        position: absolute;
        top: 20%;
        left: calc(50% - 10px);
        height: unset!important;
    }
`;

const CMButton = ({
    onClick,
    customStyle,
    text,
    children,
    loading,
    spinnerColor,
    ...otherProps
}) => {
    return (
        <StyledButton
            variant="contained"
            disableElevation
            onClick={onClick}
            style={customStyle}
            disabled={loading}
            {...otherProps}
        >
            {text || children}
            {loading && <div className="loading-spinner">
                <Spinner
                    size={20}
                    color={spinnerColor}
                />
            </div>}
        </StyledButton>
    );
}

export default CMButton;
