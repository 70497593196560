import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #0000004d;
    width: 100%;
`;

const LoadingIndicator = () => {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const tick = () => {
            setProgress(prog => prog + 20);
        };

        const timer = setInterval(tick, 240);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <Container>
            <CircularProgress 
                size={80}
                thickness={5.4}
                variant="determinate"
                value={progress}
            />
        </Container>
    );
};

export default LoadingIndicator;
