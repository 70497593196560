import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import colors, { journalTheme } from '../../constants/colors';
import Paper from '@material-ui/core/Paper';
import CMButton from '../common/CMButton';
import PencilIcon from '@material-ui/icons/Create';
import NotePad from './NotePad';
import fire from '../../fire';
import Spinner from '../common/Spinner';
import moment from 'moment';
import logAnalyticsEvent from '../../logAnalyticsEvent';

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        margin: 0;
        width: 80%;
        max-width: 320px;
    }

    .journal-container {
        padding: 12px 18px;

        .title-text {
            text-align: center;
            font-weight: 600;
            font-size: 1.1em;
            color: ${colors.greys.strong};
            padding: 9px;
        }

        .spinner-container {
            display: flex;
            justify-content: center;
        }

        .notes-container {
            padding: 12px 0;

            .MuiPaper-root {
                padding: 12px 9px;
                max-height: 300px;
                overflow-y: auto;

                .note-container {
                    display: flex;
                    align-items: center;

                    .edit-buttons-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-right: 9px;
                        max-width: 0;
                        overflow: hidden;
                        transition: max-width 0.25s ease-out;

                        &.expanded {
                            max-width: 50px;
                            transition: max-width 0.25s ease-in;
                        }

                        .MuiButton-root {
                            min-width: unset;

                            .MuiButton-startIcon {
                                margin: 0;
                            }
                        }
                    }

                    .text-container {
                        display: flex;
                        flex-direction: column;
                        font-weight: 500;
                        color: ${colors.greys.dark};
                        user-select: none;
                        width: 100%;

                        .write-dates {
                            font-size: 0.8em;
                            font-weight: 500;
                            color: ${colors.greys.strong};
                            margin-bottom: 3px;
                        }

                        .note-text {
                            font-size: 0.9em;
                            white-space: pre-line;
                        }

                        .divider {
                            border: 0.5px solid ${colors.greys.primary};
                            margin: 9px 9px 9px 0;
                        }
                    }
                }
            }
        }

        .no-note-message {
            display: flex;
            justify-content: center;
            font-size: 0.9em;
            color: ${colors.greys.strong};
            font-weight: 500;
            padding: 6px 0;
        }

        .action-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .edit-toggle {
                margin-right: 12px;

                .MuiButton-root {
                    min-width: unset;

                    .MuiButton-startIcon {
                        margin: 0;
                    }
                }
            }
        }
    }
`;

const Journal = ({ open, onClose, growID }) => {

    const [showEditButtons, setShowEditButtons] = useState(false);
    const [showNewNotePad, setShowNewNotePad] = useState(false);
    const [showEditNotePad, setShowEditNotePad] = useState({});
    const [journalNotes, setJournalNotes] = useState({});
    const [sortedJournalNotes, setSortedJournalNotes] = useState([]);
    const [loading, setLoading] = useState(true);

    const journalCollection = fire.firestore().collection('grows').doc(growID).collection('journal');

    useEffect(() => {
        if (open) {
            logAnalyticsEvent('journalOpened');
        }
    }, [open])

    useEffect(() => {
        let unsubscribe = () => {};

        if (open) {
            unsubscribe = journalCollection.onSnapshot(snapshot => {
                snapshot.docChanges().forEach(function(change) {
                    if (change.type === "added" || change.type === "modified") {
                        setJournalNotes(jn => ({ ...jn, [change.doc.id]: change.doc.data() }));
                    }
                    if (change.type === "removed") {
                        setJournalNotes(jn => ({ ...jn, [change.doc.id]: null }));
                    }
                });

                setLoading(false);
            });
        }

        return () => {
            unsubscribe();
        }
    }, [open]);

    useEffect(() => {
        const sJournalNotes = Object.keys(journalNotes).filter((noteID) => {
            return journalNotes[noteID];
        }).map((noteID) => {
            const { noteText, createdAt } = journalNotes[noteID];
            return { id: noteID, noteText, createdAt };
        }).sort((a, b) => {
            if (a.createdAt < b.createdAt) {
                return -1;
            }

            if (a.createdAt > b.createdAt) {
                return 1;
            }

            return 0;
        });

        setSortedJournalNotes(sJournalNotes);
    }, [journalNotes])

    const close = () => {
        setShowEditButtons(false);
        onClose();
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            const anchor = document.getElementById('bottom-slot');
            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 1000);
    }

    const hasNotes = sortedJournalNotes.length > 0;

    return (
        <>
            <StyledDialog open={open || false} onClose={close}>
                <div className="journal-container">
                    <div className="title-text">
                        Journal
                    </div>
                    {loading && <div className="spinner-container"><Spinner size={20} thickness={3.6} /></div>}
                    {!loading && (sortedJournalNotes.length > 0) && <div className="notes-container">
                        <Paper elevation={6}>
                            {sortedJournalNotes.map((note, idx) => {
                                const lastElm = (idx === sortedJournalNotes.length - 1);
                                return (
                                    <div key={idx} className="note-container">
                                        <div className={"edit-buttons-container" + (showEditButtons ? " expanded" : "")}>
                                            <CMButton
                                                startIcon={<PencilIcon />}
                                                size="small"
                                                onClick={() => setShowEditNotePad({ [note.id]: true })}
                                                customStyle={{
                                                    margin: 0,
                                                    marginBottom: lastElm ? 0 : 18,
                                                    padding: '2px 3px',
                                                    backgroundColor: journalTheme
                                                }}
                                            />
                                            <NotePad
                                                open={showEditNotePad[note.id]}
                                                onClose={() => setShowEditNotePad({ [note.id]: false })}
                                                noteID={note.id}
                                                existingNoteText={note.noteText}
                                                growID={growID}
                                                scrollToBottom={scrollToBottom}
                                            />
                                        </div>
                                        <div  className="text-container">
                                            <div className="write-dates">
                                                {moment.unix(note.createdAt).format("MMM Do - h:mm A")}
                                            </div>
                                            <div className="note-text">
                                                {note.noteText}
                                            </div>
                                            {!lastElm && <div className="divider"></div>}
                                        </div>
                                    </div>
                                );
                            })}
                            <div id="bottom-slot"></div>
                        </Paper>
                    </div>}
                    {!loading && !hasNotes && <div className="no-note-message">
                        No notes! Add one below
                    </div>}
                    <div className="action-container">
                        {hasNotes && <div className="edit-toggle">
                            <CMButton
                                text="Edit"
                                onClick={() => setShowEditButtons(eb => !eb)}
                                customStyle={{
                                    backgroundColor: journalTheme
                                }}
                            />
                        </div>}
                        {!loading && <CMButton
                            text="Add Note"
                            onClick={() => setShowNewNotePad(true)}
                            customStyle={{
                                backgroundColor: journalTheme
                            }}
                        />}
                    </div>
                </div>
            </StyledDialog>
            <NotePad
                open={showNewNotePad}
                onClose={() => setShowNewNotePad(false)}
                growID={growID}
                scrollToBottom={scrollToBottom}
            />
        </>
    );
}

export default Journal;
