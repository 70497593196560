import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import colors, { trashCanRed, journalTheme, journalThemeSpinner } from '../../constants/colors';
import CMButton from '../common/CMButton';
import fire from '../../fire';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmAction from '../common/ConfirmAction';
import CMAlert from '../common/CMAlert';
import logAnalyticsEvent from '../../logAnalyticsEvent';

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        margin: 0;
        width: calc(80% - 18px);
        max-width: 302px;
    }

    .notepad-container {
        padding: 12px 15px;

        .title-text {
            text-align: center;
            font-weight: 600;
            font-size: 1em;
            color: ${colors.greys.strong};
            padding: 6px 0 18px;
        }

        .text-area-container {
            width: 100%;

            .text-area-control {
                width: 100%;
                box-sizing: border-box;
                resize: none;
                font-family: "Montserrat";
                font-weight: 500;
                color: ${colors.greys.dark};
                font-size: 0.9em;
                padding: 3px 6px;
                outline: none;
                border: 0.5px solid ${colors.greys.primary};
                border-radius: 5px;
            }
        }

        .action-container {
            padding: 12px 0 6px;
            display: flex;
            justify-content: space-evenly;

            .MuiButton-root {
                min-width: unset;

                .MuiButton-startIcon {
                    margin: 0;
                }
            }
        }
    }
`;

const NotePad = ({ open, onClose, noteID, existingNoteText, growID, scrollToBottom }) => {

    const [noteText, setNoteText] = useState(existingNoteText || '');
    const [showConfirmDelete, setShowConfirmDelete] = useState({});
    const [noteUpdateLoading, setNoteUpdateLoading] = useState(false);
    const [noteDeleteLoading, setNoteDeleteLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertText, setAlertText] = useState('');

    const db = fire.firestore();
    const journalCollection = db.collection('grows').doc(growID).collection('journal');

    useEffect(() => {
        if (open && existingNoteText) {
            setNoteText(existingNoteText);
        }
    }, [open]);

    const close = () => {
        setNoteText('');
        onClose();
    }

    const editClose = () => {
        setNoteText(existingNoteText);
        onClose();
    }

    const createNewNote = async () => {
        if (noteText) {
            const noteRef = journalCollection.doc();

            setNoteUpdateLoading(true);

            await db.runTransaction(async transaction => {
                await transaction.get(noteRef).then(() => {
                    transaction.set(noteRef, {
                        noteText,
                        createdAt: moment().format('X')
                    });
                });
            })
            .then(() => {
                setTimeout(() => {
                    setNoteUpdateLoading(false);
                    close();
                    scrollToBottom();
                    logAnalyticsEvent('journalNoteAdded');
                }, 0)
            })
            .catch(() => {
                setAlertText("Unable to create note. Please check your connection and try again.");
                setOpenAlert(true);
                setNoteUpdateLoading(false);
            })
        } else {
            close();
        }
    }

    const editNote = async () => {
        if (noteText && noteID) {
            const noteRef = journalCollection.doc(noteID);

            setNoteUpdateLoading(true);

            await db.runTransaction(async transaction => {
                await transaction.get(noteRef).then(() => {
                    transaction.update(noteRef, {
                        noteText,
                        updatedAt: moment().format('X')
                    });
                });
            })
            .then(() => {
                setTimeout(() => {
                    setNoteUpdateLoading(false);
                    onClose();
                    logAnalyticsEvent('journalNoteEdited');
                }, 0);
            })
            .catch(() => {
                setAlertText("Unable to update note. Please check your connection try again.");
                setOpenAlert(true);
                setNoteUpdateLoading(false);
            })
        } else {
            editClose();
        }
    }

    const deleteNote = async (noteID) => {
        const noteRef = journalCollection.doc(noteID);

        setNoteDeleteLoading(true);

        await db.runTransaction(async transaction => {
            await transaction.get(noteRef).then(() => {
                transaction.delete(noteRef);
            });
        })
        .then(() => {
            setTimeout(() => {
                setNoteDeleteLoading(false);
                onClose();
                logAnalyticsEvent('journalNoteDeleted');
            }, 0);
        })
        .catch(() => {
            setAlertText("Unable to delete note. Please check your connection and try again.");
            setOpenAlert(true);
            setNoteDeleteLoading(false);
        })
    }

    const moveTextareaCursorToEnd = (e) => {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    return (
        <>
            <StyledDialog open={open || false} onClose={noteID ? editClose : close}>
                <div className="notepad-container">
                    <div className="title-text">
                        {noteID ? "Edit Note" : "Add Note"}
                    </div>
                    <div className="text-area-container">
                        <textarea
                            className="text-area-control"
                            rows="6"
                            value={noteText}
                            autoFocus
                            onFocus={e => moveTextareaCursorToEnd(e)}
                            onChange={e => setNoteText(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="action-container">
                        <CMButton
                            text="Cancel"
                            onClick={noteID ? editClose : close}
                            customStyle={{
                                backgroundColor: colors.greys.strong,
                                margin: 0
                            }}
                        />
                        {noteID && <><CMButton
                            startIcon={<DeleteIcon />}
                            size="large"
                            onClick={() => setShowConfirmDelete({ [noteID]: true })}
                            customStyle={{
                                margin: 0,
                                backgroundColor: trashCanRed
                            }}
                        />
                        <ConfirmAction
                            open={showConfirmDelete[noteID]}
                            onClose={() => setShowConfirmDelete({})}
                            confirmButtonText="Delete"
                            onConfirm={() => deleteNote(noteID)}
                            confirmButtonCustomStyle={{
                                backgroundColor: journalTheme
                            }}
                            loading={noteDeleteLoading}
                            spinnerColor={journalThemeSpinner}
                        /></>}
                        <CMButton
                            text={noteID ? "Update" : "Add"}
                            onClick={noteID ? editNote : createNewNote}
                            customStyle={{
                                margin: 0,
                                backgroundColor: journalTheme,
                                width: 70
                            }}
                            loading={noteUpdateLoading}
                            spinnerColor={journalThemeSpinner}
                        />
                    </div>
                </div>
            </StyledDialog>
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertText('');
                }}
                mainMessage={alertText}
            />
        </>
    );
}

export default NotePad;
