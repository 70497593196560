import { combineReducers } from 'redux';
import { ACTIONS } from './actions';

const devices = (state = {}, action) => {
    switch(action.type) {
        case ACTIONS.ADD_DEVICE:
            const { deviceID, deviceConfig } = action.payload;
            return Object.assign({}, state, { [deviceID]: deviceConfig });
        case ACTIONS.REMOVE_DEVICE:
            const reducedState = Object.assign({}, state);
            delete reducedState[action.deviceID];
            return reducedState;
        case ACTIONS.SET_CURRENT_DEVICE:
            return { ...state, currentDevice: action.deviceID };
        default:
            return state;
    }
}

const accessInitialState = {
    peripherals: true,
    clientConnectionAlert: false,
    canopyMateConnectionAlert: false
}

const access = (state = accessInitialState, action) => {
    switch(action.type) {
        case ACTIONS.DISABLE_PERIPHERAL_ACCESS:
            return { ...state, peripherals: false };
        case ACTIONS.ENABLE_PERIPHERAL_ACCESS:
            return { ...state, peripherals: true };
        case ACTIONS.SHOW_CLIENT_CONNECTION_ALERT:
            return { ...state, clientConnectionAlert: true };
        case ACTIONS.CLOSE_CLIENT_CONNECTION_ALERT:
            return { ...state, clientConnectionAlert: false };
        case ACTIONS.SHOW_CANOPY_MATE_CONNECTION_ALERT:
            return { ...state, canopyMateConnectionAlert: true };
        case ACTIONS.CLOSE_CANOPY_MATE_CONNECTION_ALERT:
            return { ...state, canopyMateConnectionAlert: false };
        default:
            return state;
    }
}

const connection = (state = {}, action) => {
    switch(action.type) {
        case ACTIONS.WRITE_LAST_HEARTBEAT:
            const { deviceID, seconds } = action.payload;
            return { ...state, [deviceID]: seconds };
        default:
            return state;
    }
}

const initalGrowState = {
    current: {},
    past: {}
}

const grows = (state = initalGrowState, action) => {
    switch(action.type) {
        case ACTIONS.ADD_GROW:
            const { growID, growData, category } = action.payload;
            return { ...state, [category]: { ...state[category], [growID]: growData } };
        case ACTIONS.REMOVE_GROW:
            const reducedState = Object.assign({}, state);
            delete reducedState[action.payload.category][action.payload.growID];
            return reducedState;
        default:
            return state;
    }
}

export default combineReducers({
    devices,
    access,
    connection,
    grows
});
