import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import GridOnIcon from '@material-ui/icons/GridOn';
import SpaIcon from '@material-ui/icons/Spa';
import colors from '../../constants/colors';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import fire from '../../fire';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import ForumIcon from '@material-ui/icons/Forum';

const StyledNavigation = styled(Drawer)`
    .MuiDrawer-paper {
        width: 70%;
        max-width: 275px;
        font-weight: 500;
    }

    .title-container {
        justify-content: center;
        padding-bottom: 16px;

        .title {
            color: ${colors.greys.dark};
            user-select: none;
            font-size: 1.25em;
        }
    }

    .sign-out-container {
        margin-top: auto;
        padding-bottom: 0;
    }
`;

const StyledNavigationItem = styled(ListItem)`
    &.MuiButtonBase-root {
        margin: 8px 0;
    }

    .nav-item-icon {
        min-width: unset;
        padding-right: 16px;

        .MuiSvgIcon-root {
            font-size: 1.8rem;
        }
    }

    .nav-item-text {
        font-size: 1.1em;
        color: ${colors.greys.dark};
    }
`;

const NavigationItem = ({ route, icon, text, onClose }) => {

    const history = useHistory();

    return (
        <StyledNavigationItem
            button
            onClick={() => {
                history.push(route);
                onClose();
            }}
        >
            <ListItemIcon className="nav-item-icon">
                {icon}
            </ListItemIcon>
            <span className="nav-item-text">
                {text}
            </span>
        </StyledNavigationItem>
    );
};

const Navigation = ({ open, onClose }) => {

    const history = useHistory();

    return (
        <StyledNavigation
            open={open}
            onClose={onClose}
        >
            <List>
                <ListItem className="title-container">
                    <div className="title">
                        Canopy Mate
                    </div>
                </ListItem>
                <Divider />
                <NavigationItem 
                    route={ROUTES.HOME}
                    icon={<SettingsBrightnessIcon />}
                    text={'Controls'}
                    onClose={onClose}
                />
                <NavigationItem 
                    route={ROUTES.GROWS}
                    icon={<SpaIcon />}
                    text={'Grows'}
                    onClose={onClose}
                />
                <NavigationItem 
                    route={ROUTES.DEVICES}
                    icon={<GridOnIcon />}
                    text={'Canopy Mates'}
                    onClose={onClose}
                />
                <Divider />
                <StyledNavigationItem
                    button
                    onClick={() => {
                        let a = document.createElement('a');
                        a.target= '_blank';
                        a.href= 'https://www.facebook.com/groups/817200762197959/?ref=share';
                        a.click();
                        onClose();
                    }}
                >
                    <ListItemIcon className="nav-item-icon">
                        <ForumIcon />
                    </ListItemIcon>
                    <span className="nav-item-text">
                        Facebook Group
                    </span>
                </StyledNavigationItem>
            </List>
            <List className="sign-out-container">
                <Divider />
                <StyledNavigationItem
                    button
                    onClick={() => {
                        history.push(ROUTES.HOME);
                        fire.auth().signOut();
                    }}
                >
                    <ListItemIcon className="nav-item-icon">
                        <PersonIcon />
                    </ListItemIcon>
                    <span className="nav-item-text">
                        Sign Out
                    </span>
                </StyledNavigationItem>
            </List>
        </StyledNavigation>
    );
};

export default Navigation;
