import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './config/configureStore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import fire from './fire';

WebFont.load({
    google: {
        families: [
            'Montserrat:300,400,500,600,700,900',
            'Work Sans:300,500,700,900',
            'sans-serif'
        ]
    }
})

export const store = configureStore();

fire.analytics();

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <App />
            </MuiPickersUtilsProvider>
        </Provider>
    </BrowserRouter>, 
    document.getElementById('root')
);

if (navigator && navigator.serviceWorker && navigator.serviceWorker.controller) {
    serviceWorker.unregister();
    window.location.reload();
} else {
    serviceWorker.register();
}
