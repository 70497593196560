import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../common/Header';
import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors, { connectionColors, appBackground, buttonBackground, cardBackground } from '../../constants/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddDeviceModal from '../common/AddDeviceModal';
import { CURRENT_DEVICE } from '../../constants/storageKeys';
import Checkbox from '@material-ui/core/Checkbox';
import fire from '../../fire';
import CMButton from '../common/CMButton';
import Spinner from '../common/Spinner';
import ConfirmAction from '../common/ConfirmAction';
import firebase from 'firebase/app';
import CMAlert from '../common/CMAlert';
import logAnalyticsEvent from '../../logAnalyticsEvent';

const CONNECTION_SECONDS_THRESHOLD = 120;

const Container = styled.div`
    min-height: 100vh;
    position: relative;

    .sub-container {
        min-height: calc(100vh - 56px);
        background-color: ${appBackground};

        .no-device-container {
            display: flex;
            justify-content: center;
            padding-top: 24px;

            .MuiCard-root {
                width: 80%;
                padding: 12px;
                max-width: 350px;
                background-color: ${cardBackground};

                .no-device-text {
                    text-align: center;
                    font-weight: 500;
                    color: ${colors.greys.dark};
                }
            }
        }
    }

    @media (min-width: 600px) {
        .sub-container {
            min-height: calc(100vh - 64px);
        }
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .fab-container {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        padding-bottom: 1em;

        .MuiFab-root {
            text-transform: unset;    
        } 

        .fab {
            background-color: ${buttonBackground};
            color: ${colors.white};

            .icon {
                font-size: 2em;
            }

            .MuiFab-label {
                font-family: "Montserrat";
            }
        }
    }
`;

const DeviceDisplayCard = styled.div`
    display: flex;
    justify-content: center;

    .MuiCard-root {
        width: 80%;
        padding: 12px;
        max-width: 350px;
        margin-top: 1em;
        background-color: ${cardBackground};
    }

    .device-name {
        font-size: 1.1em;
        font-weight: 600;
        padding: 0 0 8px 0;
        color: ${colors.greys.strong};
        line-height: 2em;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;

        .connection-dot {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: ${props => props.connectionColor};
            box-shadow: 0px 0 8px 5px ${props => props.connectionColor};
            display: inline-block;
            margin-left: 12px;
            margin-bottom: 4px;
        }

        .connection-text {
            margin-left: 12px;
            font-size: 0.8em;
            color: ${colors.greys.strong};
            font-weight: 500;
        }
    }

    .device-options {
        display: flex;
        flex-direction: column;
        padding: 12px;
        width: 100%;

        .device-name-update {
            display: grid;
            grid-template-columns: auto 65px;
            margin-bottom: 6px;

            .button-container {
                display: flex;
                align-items: flex-end;
            }

            .MuiTextField-root {
                max-width: 60%;
            }

            .input-field {
                display: flex;
                flex-direction: column;
                margin-right: 12px;

                .text-input {
                    font-family: "Montserrat";
                    font-size: 0.9em;
                    border-radius: 4px;
                    padding: 3px;
                    border: solid 1px #9A9A9A;
                    height: 24px;
                    width: 95%;
                }

                .label-text {
                    margin-bottom: 5px;
                    color: ${colors.greys.dark};
                    font-weight: 600;
                }
            }
        }

        .divider {
            border: 0.5px solid ${colors.greys.primary};
            margin: 16px 2px 0;
        }

        .internal-fan-checkbox-container {
            margin-top: 16px;
            display: flex;
            align-items: center;
            margin-left: -9px;

            .internal-fan-checkbox {
                color: ${buttonBackground};
            }

            .internal-fan-checkbox-label {
                font-weight: 600;
                color: ${colors.greys.dark};
            }
        }

        .remove-device-button {
            ${'' /* padding-left: 10px; */}
        }
    }

    .MuiExpansionPanelSummary-content {
        font-weight: 500;

        &.Mui-expanded {
            margin: 12px 0;
        }
    }

    .MuiExpansionPanelSummary-root {
        padding: 0 24px 0 12px;
        color: ${colors.greys.dark};

        .MuiIconButton-root {
            padding: 6px 12px;
        }

        &.Mui-expanded {
            min-height: 48px;
        }
    }

    .MuiExpansionPanelDetails-root {
        padding: 0;
    }

    .MuiExpansionPanel-root {
        border-radius: 4px;

        &:before {
            background-color: unset;
        }

        &.Mui-expanded {
            margin: unset;
        }
    }

    .options-text {
        font-size: 1.1em;
        color: ${colors.greys.dark};
        font-weight: 600;
    }
`;

const Devices = () => {
    const [openRemoveDeviceModal, setOpenRemoveDeviceModal] = useState({});
    const [name, setName] = useState({});
    const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
    const [removeDeviceLoading, setRemoveDeviceLoading] = useState(false);
    const [updateNameLoading, setUpdateNameLoading] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const devices = useSelector(state => state.devices);
    const connection = useSelector(state => state.connection);

    const { currentDevice, ...usersDevices } = devices;

    const handleCloseRemoveDeviceModal = deviceID => {
        setOpenRemoveDeviceModal(deviceState => ({ ...deviceState, [deviceID]: false }));
    };

    const getConnectionStatus = connectionStatus => {
        return connectionStatus ? (
            connectionStatus < CONNECTION_SECONDS_THRESHOLD ? '(Online)' : '(Offline)'
        ) : 'Loading...'
    }

    const getConnectionColor = connectionStatus => {
        return connectionStatus ? (
            connectionStatus < CONNECTION_SECONDS_THRESHOLD ? connectionColors.green : connectionColors.red
        ) : connectionColors.grey
    }

    const setInternalFan = (deviceID, checkValue) => {
        fire.firestore().collection('devices').doc(deviceID).update({
            'using_internal_fan': checkValue
        })
        .then(() => {
            logAnalyticsEvent('circulationFanToggled');
        })
    }

    const updateDeviceName = async (deviceID) => {
        const db = fire.firestore();
        const deviceRef = db.collection('devices').doc(deviceID);

        setUpdateNameLoading(nl => ({ ...nl, [deviceID]: true }))

        await db.runTransaction(async transaction => {
            await transaction.get(deviceRef).then(() => {
                transaction.update(deviceRef, {
                    name: name[deviceID].trim()
                });
            });
        })
        .then(() => {
            setUpdateNameLoading(nl => ({ ...nl, [deviceID]: false }));
            setName(n => ({ ...n, [deviceID]: '' }));
            logAnalyticsEvent('nameUpdated');
        })
        .catch(() => {
            setUpdateNameLoading(nl => ({ ...nl, [deviceID]: false }));
            setAlertMessage("Unable to update name. Please check your connection and try again.");
            setOpenAlert(true);
        })
    };

    const removeDevice = async (deviceID) => {
        const db = fire.firestore();
        const userID = fire.auth().currentUser.uid;

        const deleteJournalEntries = firebase.functions().httpsCallable('deleteJournalEntries');

        const deviceRef = db.collection('devices').doc(deviceID);
        const userRef = db.collection('users').doc(userID);

        setRemoveDeviceLoading(true);

        let growID;

        await db.runTransaction(async transaction => {
            await transaction.get(deviceRef).then(deviceDoc => {
                const { currentGrow } = deviceDoc.data();

                if (currentGrow) {
                    growID = currentGrow;

                    transaction.delete(db.collection('grows').doc(currentGrow))
                    .update(userRef, {
                        'grows.current': firebase.firestore.FieldValue.arrayRemove(currentGrow)
                    })
                    .update(deviceRef, {
                        currentGrow: firebase.firestore.FieldValue.delete()
                    });
                }

                transaction.update(deviceRef, {
                    owner: firebase.firestore.FieldValue.delete(), 
                    name: firebase.firestore.FieldValue.delete()
                })
                .update(userRef, {
                    devices: firebase.firestore.FieldValue.arrayRemove(deviceID)
                })
            })
        })
        .then(() => {
            setRemoveDeviceLoading(false);
            handleCloseRemoveDeviceModal(deviceID);
            logAnalyticsEvent('canopyMateRemoved');

            if (growID) {
                deleteJournalEntries({ growID })
                .then(() => {
                    console.log('deleted journal');
                })
                .catch((error) => {
                    console.warn(error);
                });
            }
        })
        .catch(() => {
            setRemoveDeviceLoading(false);
            setAlertMessage("Unable to remove Canopy Mate. Please check your connection and try again.");
            setOpenAlert(true);
        });
    }
    
    let currentDeviceLS = localStorage.getItem(CURRENT_DEVICE);
    currentDeviceLS = (currentDeviceLS === 'undefined' ? undefined : currentDeviceLS);

    const deviceInfoLoading = currentDeviceLS && Object.keys(usersDevices).length === 0;

    return (
        <Container>
            <Header />
            <div className="sub-container">
                {deviceInfoLoading && <div className="loading-spinner">
                    <Spinner
                        size={40}
                    />
                </div>}
                {!currentDeviceLS && <div className="no-device-container">
                    <Card>
                        <div className="no-device-text">
                            Add a Canopy Mate below to get started!
                        </div>
                    </Card>
                </div>}
                {Object.keys(usersDevices).map((deviceID, idx) => (
                    <DeviceDisplayCard key={idx} connectionColor={() => getConnectionColor(connection[deviceID])}>
                        <Card>
                            <div className="device-name">
                                {usersDevices[deviceID].name}
                                <span className="connection-dot"></span>
                                <span className="connection-text">
                                    {getConnectionStatus(connection[deviceID])}
                                </span>
                            </div>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className="options-text">Options</div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="device-options">
                                        <div className="device-name-update">
                                            <label className="input-field">
                                                <span className="label-text">Update Name:</span>
                                                <input
                                                    className="text-input"
                                                    type="text"
                                                    value={name[deviceID] || ''}
                                                    onChange={e => {
                                                        e.persist();
                                                        setName(n => ({ ...n, [deviceID]: e.target.value }))
                                                    }}
                                                />
                                            </label>
                                            <div className="button-container">
                                                {name[deviceID] && name[deviceID].trim() && <CMButton
                                                    text="Update"
                                                    onClick={() => updateDeviceName(deviceID)}
                                                    loading={updateNameLoading[deviceID]}
                                                    customStyle={{ 
                                                        margin: '16px 0 0 0',
                                                        color: colors.white
                                                    }}
                                                />}
                                            </div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="internal-fan-checkbox-container">
                                            <Checkbox
                                                className="internal-fan-checkbox"
                                                checked={usersDevices[deviceID].using_internal_fan || false}
                                                onChange={e => setInternalFan(deviceID, e.target.checked)}
                                            />
                                            <div className="internal-fan-checkbox-label">Use circulation fan</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="remove-device-button">
                                            <CMButton
                                                text="Remove Canopy Mate"
                                                onClick={() => setOpenRemoveDeviceModal(deviceState => ({ ...deviceState, [deviceID]: true }))}
                                                customStyle={{ 
                                                    backgroundColor: colors.greys.strong, 
                                                    margin: '22px 0 0 0'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Card>
                        <ConfirmAction
                            open={openRemoveDeviceModal[deviceID]}
                            onClose={() => handleCloseRemoveDeviceModal(deviceID)}
                            subMessage={usersDevices[deviceID].currentGrow && "You currently have an active grow! Removing this Canopy Mate will delete that grow and all journal entries."}
                            onConfirm={() => removeDevice(deviceID)}
                            loading={removeDeviceLoading}
                        />
                    </DeviceDisplayCard>
                ))}
                {!deviceInfoLoading && <div className="fab-container">
                    <Fab
                        className="fab"
                        variant="extended"
                        onClick={() => setOpenAddDeviceModal(true)}
                    >
                        <AddIcon className="icon" />
                        Add Canopy Mate
                    </Fab>
                </div>}
            </div>
            <AddDeviceModal
                open={openAddDeviceModal}
                handleClose={() => setOpenAddDeviceModal(false)}
            />
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
        </Container>
    );
};

export default Devices;
