import React from 'react';
import styled from 'styled-components';

const StyledSplash = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 24px;

    .image {
        width: 50vw;
        height: 50vw;
        max-width: 400px;
        max-height: 400px;
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0%, 100% {
            opacity: 100%;
        }

        50% {
            opacity: 10%;
        }
    }
`;

const Splash = () => {
    return (
        <StyledSplash>
            <img
                className="image"
                src="images/logos/logo-green-round-small.png"
                alt="logo"
            />
        </StyledSplash>
    );
};

export default Splash;
