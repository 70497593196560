import React, { useState } from 'react';
import styled from 'styled-components';
import colors, { journalTheme } from '../../constants/colors';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import StartDatePicker from './StartDatePicker';
import CMButton from '../common/CMButton';
import fire from '../../fire';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from '../../constants/formats';
import firebase from 'firebase/app';
import { HAS_CURRENT_GROWS } from '../../constants/storageKeys';
import Spinner from '../common/Spinner';
import ConfirmAction from '../common/ConfirmAction';
import CMAlert from '../common/CMAlert';
import Journal from './Journal';
import logAnalyticsEvent from '../../logAnalyticsEvent';
import { turnOffAllPeripherals } from '../peripherals/update';

const Container = styled.div`
    padding: 8px;

    .spinner-container {
        text-align: center;
        padding: 12px 0;
    }

    .lead-text {
        color: ${colors.greys.strong};
        font-weight: 600;
        margin: 4px;
        font-size: 1.1em;
        padding-left: 12px;

        &.no-grows {
            padding-left: 0;
            text-align: center;
        }
    }

    .panel-container {
        margin: 8px 4px;

        .MuiExpansionPanel-root {
            border-radius: 4px;

            &:before {
                background-color: unset;
            }

            &.Mui-expanded {
                margin: unset;
            }
        }

        .MuiExpansionPanelSummary-root {
            padding: 0 12px;
            color: ${colors.greys.strong};
            font-size: 1.1em;

            .MuiExpansionPanelSummary-content {
                font-weight: 600;

                &.Mui-expanded {
                    margin: 12px 0;
                }
            }

            .MuiIconButton-root {
                padding: 6px 12px;
            }

            &.Mui-expanded {
                min-height: 48px;
            }
        }

        .MuiExpansionPanelDetails-root {
            padding: 6px 12px 12px;
        }

        .grow-content-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .divider {
                border: 0.5px solid ${colors.greys.primary};
                margin: 18px auto;
                width: 100%;
            }

            .component-container {
                ${'' /* width: 100%;
                margin: 0 auto; */}
                padding-left: 6px;
            }
            
            .start-date-picker-container {
                ${'' /* width: 100%; */}
                ${'' /* margin-bottom: 12px; */}
            }

            .flowering-date-picker-container {
                ${'' /* width: 100%; */}
            }

            .end-grow-button-container {
                margin-bottom: 6px;
            }
        }
    }

`;

const Current = ({ switchToPastTab }) => {
    const grows = useSelector(state => state.grows.current);
    const devices = useSelector(state => state.devices);

    const [floweringStartLoading, setFloweringStartLoading] = useState({});
    const [openConfirmEndGrow, setOpenConfirmEndGrow] = useState({})
    const [growEndLoading, setGrowEndLoading] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openJournal, setOpenJournal] = useState({});

    const { currentDevice, ...usersDevices } = devices;

    const growIDs = Object.keys(grows);
    const growsLoaded = growIDs.length > 0;

    const devicesLoaded = Object.keys(usersDevices).length > 0;

    const dataLoaded = growsLoaded && devicesLoaded;

    const hasCurrentGrows = localStorage.getItem(HAS_CURRENT_GROWS);

    const startFlowering = async (growID) => {
        const db = fire.firestore();
        const growRef = db.collection('grows').doc(growID);

        setFloweringStartLoading(sl => ({ ...sl, [growID]: true }));
    
        await db.runTransaction(async transaction => {
            await transaction.get(growRef).then(() => {
                transaction.update(growRef, {
                    floweringStartDate: moment().format(MOMENT_DATE_FORMAT)
                })
                .update(growRef, {
                    flowering: true
                });
            })
        })
        .then(() => {
            setFloweringStartLoading(sl => ({ ...sl, [growID]: false }));
        })
        .catch(() => {
            setFloweringStartLoading(sl => ({ ...sl, [growID]: false }));
            setAlertMessage("Unable to start flowering. Please check your connection and try again.");
            setOpenAlert(true);
        });
    }

    const endGrow = async (growID) => {
        const db = fire.firestore();
        const userID = fire.auth().currentUser.uid;
    
        const growRef = db.collection('grows').doc(growID);
        const userRef = db.collection('users').doc(userID);

        const { deviceID } = grows[growID];
        const deviceRef = db.collection('devices').doc(deviceID);

        setGrowEndLoading(el => ({ ...el, [growID]: true }));
    
        await db.runTransaction(async transaction => {
            await transaction.get(growRef).then(() => {
                transaction.update(userRef, {
                    'grows.current': firebase.firestore.FieldValue.arrayRemove(growID),
                    'grows.past': firebase.firestore.FieldValue.arrayUnion(growID)
                })
                .update(deviceRef, {
                    currentGrow: firebase.firestore.FieldValue.delete()
                })
                .update(growRef, {
                    active: false,
                    endDate: moment().format(MOMENT_DATE_FORMAT),
                    totalGrowDays: moment().diff(moment(grows[growID].startDate), 'days')
                })
            })
        })
        .then(() => {
            setGrowEndLoading(el => ({ ...el, [growID]: false }));
            switchToPastTab();
            logAnalyticsEvent('growEnded');
            turnOffAllPeripherals(deviceID);
        })
        .catch(() => {
            setGrowEndLoading(el => ({ ...el, [growID]: false }));
            setAlertMessage("Unable to end grow. Please check your connection and try again.");
            setOpenAlert(true);
        });
    }

    return (
        <>
            <Container>
                {!dataLoaded && hasCurrentGrows && <div className="spinner-container">
                    <Spinner size={25} />
                </div>}
                {!dataLoaded && !hasCurrentGrows && <div className="lead-text no-grows">
                    No current grows
                </div>}
                {dataLoaded && <div className="lead-text">
                    Growing in:
                </div>}
                {dataLoaded && growIDs.map((growID, idx) => {
                    const buttonWidth = 135;
                    return (
                        <div key={idx} className="panel-container">
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className="device-name">
                                        {grows[growID] && grows[growID].deviceID && usersDevices[grows[growID].deviceID] && usersDevices[grows[growID].deviceID].name}
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="grow-content-container">
                                        <div className="component-container">
                                            <CMButton
                                                text="Journal"
                                                onClick={() => setOpenJournal({ [growID]: true })}
                                                customStyle={{
                                                    margin: 0,
                                                    backgroundColor: journalTheme,
                                                    minWidth: buttonWidth
                                                }}
                                            />
                                            <Journal
                                                open={openJournal[growID]}
                                                onClose={() => setOpenJournal({})}
                                                growID={growID}
                                            />
                                        </div>
                                        <div className="divider"></div>
                                        <div className="start-date-picker-container component-container">
                                            <StartDatePicker
                                                growID={growID}
                                                field="startDate"
                                                growStartDate={grows[growID].startDate}
                                                label="Grow start date"
                                                buttonWidth={buttonWidth}
                                            />
                                        </div>
                                        <div className="divider"></div>
                                        <div className="flowering-date-picker-container component-container">
                                            {!grows[growID].flowering && <CMButton
                                                text="Start flowering"
                                                onClick={() => startFlowering(growID)}
                                                loading={floweringStartLoading[growID]}
                                                customStyle={{ 
                                                    margin: 0,
                                                    minWidth: buttonWidth 
                                                }}
                                            />}
                                            {grows[growID].flowering && <StartDatePicker
                                                growID={growID}
                                                field="floweringStartDate"
                                                growStartDate={grows[growID].floweringStartDate}
                                                label="Flowering start date"
                                                buttonWidth={buttonWidth}
                                            />}
                                        </div>
                                        <div className="divider"></div>
                                        <div className="end-grow-button-container component-container">
                                            <CMButton
                                                text="End grow"
                                                onClick={() => setOpenConfirmEndGrow(eg => ({ ...eg, [growID]: true }))}
                                                customStyle={{
                                                    margin: 0, 
                                                    backgroundColor: colors.greys.strong,
                                                    minWidth: buttonWidth 
                                                }}
                                            />
                                            <ConfirmAction
                                                open={openConfirmEndGrow[growID]}
                                                onClose={() => setOpenConfirmEndGrow(eg => ({ ...eg, [growID]: false }))}
                                                onConfirm={() => endGrow(growID)}
                                                loading={growEndLoading[growID]}
                                                confirmMessage="Finished this grow?"
                                                subMessage="You won't be able to start this grow again, but you'll still have access to your journal. Your fan, light and pump will also be turned off."
                                            />
                                        </div>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    )
                })}
            </Container>
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
        </>
    );
}

export default Current;
