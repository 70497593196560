import React, { useState } from 'react'
import styled from 'styled-components';
import { DurationSlider } from '../peripherals';
import moment from 'moment';
import colors, { homePageControl } from '../../constants/colors';
import styles from '../../constants/styles';
import fire from '../../fire';
import Dialog from '@material-ui/core/Dialog';
import Slider from '@material-ui/core/Slider';
import CMButton from '../common/CMButton';
import CMAlert from '../common/CMAlert';
import ConfirmAction from '../common/ConfirmAction';
import logAnalyticsEvent from '../../logAnalyticsEvent';
import Axios from 'axios';
import { APP_ENGINE_URL } from '../../constants/formats';
import CryptoJS from 'crypto-js';

const PumpRunReport = styled.div`
    width: 90%;
    max-width: 210px;
    padding: 8px 0 12px 0;
    color: ${colors.homePageLabel};
    font-size: 0.925em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        padding-bottom: 3px;
        font-weight: ${styles.homePageLabelFontWeight};
        color: ${colors.greys.strong};
    }

    .time-text {
        font-weight: ${styles.homePageLabelFontWeight};
    }
`;

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        padding: 30px 40px 20px;
        width: 210px;
    }
`;

const SliderContainer = styled.div`
    .label {
        color: ${colors.homePageLabel};
        font-weight: ${styles.homePageLabelFontWeight};
    }

    .MuiSlider-root {
        color: ${homePageControl};
    }

    .button-container {
        display: flex;
        justify-content: space-between;
    }
`;

const PumpSettings = ({ deviceSettings }) => {

    const [open, setOpen] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [openResetAlert, setOpenResetAlert] = useState(false);
    const [openConfirmReset, setOpenConfirmReset] = useState(false);

    const resetSchedule = async () => {
        setResetLoading(true);

        const payload = { deviceID: deviceSettings.device_id };

        const encryptedDeviceData = CryptoJS.AES.encrypt(JSON.stringify(payload), 'c4nopym4t3').toString();
        
        await Axios.post(APP_ENGINE_URL + "/resetPumpSchedule", { encryptedDeviceData }).then(response => {
            if (response.data.commandSent) {
                setOpenConfirmReset(false);
                logAnalyticsEvent('resetPumpSchedule');
            } else {
                setOpenResetAlert(true);
            }
            setResetLoading(false);
        });
    }

    const pumpOnSeconds = deviceSettings.pump_on_duration;
    const pumpOff = deviceSettings.pump_off_duration;
    const pumpOffHours = pumpOff / 3600;

    const lastPumpRun = deviceSettings.pump_last_run_end_time * 1000;

    const lastRunDisplay = moment(lastPumpRun).calendar();
    const nextRunDisplay = moment(lastPumpRun).add(pumpOffHours, 'hours').calendar()

    let onSliderLabel;

    switch(pumpOnSeconds) {
        case 0:
            onSliderLabel = "Pump schedule is off";
            break;
        case 1:
            onSliderLabel = "Run for 1 second";
            break;
        default:
            onSliderLabel = `Run for ${pumpOnSeconds} seconds`;
    }

    const scheduleOn = pumpOnSeconds > 0;

    return (
        <>
            <DurationSlider
                durationSeconds={pumpOnSeconds}
                label={onSliderLabel}
                field={"pump_on_duration"}
                step={5}
                min={0}
                max={120}
                stepSeconds={1}
                pumpOnSlider={true}
            />
            {scheduleOn && <DurationSlider
                durationSeconds={pumpOff}
                label={`Every ${pumpOffHours} hour${pumpOffHours === 1 ? '' : 's'}`}
                field={"pump_off_duration"}
                step={1}
                min={1}
                max={48}
                stepSeconds={3600}
                pumpOffSlider={true}
            />}
            <CMButton
                text="Manual control"
                onClick={() => setOpen(true)}
            />
            {scheduleOn && <><PumpRunReport>
                <div className="title">{deviceSettings.pump_schedule_reset ? "Schedule reset:" : "Last ran:"}</div>
                <div className="time-text">{lastRunDisplay}</div>
            </PumpRunReport>
            <PumpRunReport>
                <div className="title">Next run:</div>
                <div className="time-text">{nextRunDisplay}</div>
            </PumpRunReport>
            <CMButton
                text="Reset pump schedule"
                onClick={() => setOpenConfirmReset(true)}
                customStyle={{ margin: '12px 0' }}
            />
            <ConfirmAction
                open={openConfirmReset}
                onClose={() => setOpenConfirmReset(false)}
                confirmMessage="Reset Schedule?"
                subMessage={`Your pump will next run ${pumpOffHours} hour${pumpOffHours === 1 ? '' : 's'} from now if reset`}
                confirmButtonText="Reset"
                onConfirm={() => resetSchedule()}
                loading={resetLoading}
            />
            <CMAlert
                open={openResetAlert}
                onClose={() => setOpenResetAlert(false)}
                headMessage="Communication issue"
                mainMessage="Unable to reset pump schedule at this time. Please check your connection and try again."
            /></>}
            <ManualRunDialog
                open={open}
                handleClose={() => setOpen(false)}
                deviceID={deviceSettings.device_id}
            />
        </>
    );
};

const ManualRunDialog = ({ open, handleClose, deviceID }) => {

    const [sliderSeconds, setSliderSeconds] = useState(5);
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const runPump = async (seconds) => {
        setLoading(true);

        const payload = { deviceID, seconds };

        const encryptedDeviceData = CryptoJS.AES.encrypt(JSON.stringify(payload), 'c4nopym4t3').toString();
        
        await Axios.post(APP_ENGINE_URL + "/manualPumpRun", { encryptedDeviceData }).then(response => {
            if (response.data.commandSent) {
                handleClose();
                setSliderSeconds(5);
                logAnalyticsEvent('manualPumpRun', {
                    seconds
                });
            } else {
                setOpenAlert(true);
            }
            setLoading(false);
        });
    }

    const label = `Run for ${sliderSeconds} second${sliderSeconds > 1 ? 's' : ''}`

    return (
        <StyledDialog open={open} onClose={handleClose}>
            <SliderContainer>
                <div className="label">
                    {label}
                </div>
                <Slider
                    value={sliderSeconds}
                    valueLabelDisplay="auto"
                    step={5}
                    marks
                    min={5}
                    max={60}
                    onChange={(e, v) => setSliderSeconds(v)}
                />
                <div className="button-container">
                    <CMButton
                        text="Cancel"
                        onClick={handleClose}
                        customStyle={{ backgroundColor: colors.greys.primary }}
                    />
                    <CMButton
                        text="Water"
                        onClick={() => runPump(sliderSeconds)}
                        loading={loading}
                    />
                    <CMAlert
                        open={openAlert}
                        onClose={() => setOpenAlert(false)}
                        headMessage="Communication issue"
                        mainMessage="Unable to run pump at this time. Please try again."
                    />
                </div>
            </SliderContainer>
        </StyledDialog>
    )
};

export default PumpSettings;
