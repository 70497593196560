import React, { useState } from 'react';
import styled from 'styled-components';
import fire from '../../fire';
import colors from '../../constants/colors';
import Dialog from '@material-ui/core/Dialog';
import CMButton from '../common/CMButton';
import CMAlert from '../common/CMAlert';

const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        padding: 16px;
    }

    .title {
        text-align: center;
        margin: 6px 0 18px;
        font-weight: 600;
        color: ${colors.greys.dark};
    }

    .input-field {
        display: flex;
        flex-direction: column;
        padding: 0 12px;

        .text-input {
            font-family: "Montserrat";
            font-size: 0.9em;
            border-radius: 4px;
            padding: 3px;
            border: solid 1px ${colors.greys.strong};
        }

        .label-text {
            margin-bottom: 3px;
            font-weight: 500;
            color: ${colors.greys.dark};
        }
    }

    .success-message {
        text-align: center;
        font-weight: 600;
        color: ${colors.greys.strong};
        margin-top: 24px;
    }
`;

const PasswordRecoveryModal = ({ open, handleClose }) => {
    const [email, setEmail] = useState('');
    const [showSentMessage, setShowSentMessage] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendRecoveryEmail = () => {
        setLoading(true);

        fire.auth().sendPasswordResetEmail(email)
            .then(() => {
                setLoading(false);
                setEmail('');
                setShowSentMessage(true);
                setTimeout(() => {
                    handleClose();
                    setShowSentMessage(false);
                }, 5000);
            })
            .catch(error => {
                setLoading(false);
                if (error) {
                    setOpenErrorAlert(true);
                }
            });
    }

    return (
        <StyledDialog open={open} onClose={handleClose}>
            <div className="title">
                Account Recovery
            </div>
            <label className="input-field email-input">
                <span className="label-text">Email:</span>
                <input
                    className="text-input"
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
            </label>
            {showSentMessage && <div className="success-message">
                Success! Check your email
            </div>}
            <CMButton
                text="Send recovery email"
                onClick={sendRecoveryEmail}
                customStyle={{ 
                    margin: '24px 12px 8px'
                }}
                loading={loading}
            />
            <CMAlert
                open={openErrorAlert}
                onClose={() => setOpenErrorAlert(false)}
                mainMessage="Please check the email you entered"
            />
        </StyledDialog>
    );
};

export default PasswordRecoveryModal;
