import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../common/Header';
import { FanSwitch } from '../peripherals';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import PumpSettings from './PumpSettings';
import LightSettings from './LightSettings';
import colors, { appBackground, cardBackground } from '../../constants/colors';
import moment from 'moment';
import DeviceSelect from '../common/DeviceSelect';
import { CURRENT_DEVICE } from '../../constants/storageKeys';
import CMButton from '../common/CMButton';
import fire from '../../fire';
import { MOMENT_DATE_FORMAT } from '../../constants/formats';
import firebase from 'firebase/app';
import Spinner from '../common/Spinner';
import CMAlert from '../common/CMAlert';
import { closeClientConnectionAlert, closeCanopyMateConnectionAlert } from '../../config/actions';
import logAnalyticsEvent from '../../logAnalyticsEvent';

const HomeContainer = styled.div`
    min-height: 100vh;
    background-color: ${appBackground};

    .loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .peripherals {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 64px);

        & > * {
            padding: 5px;
        }

        .peripheral-card {
            width: 80%;
            max-width: 350px;
            margin-top: 1em;
            padding: 12px;
            background-color: ${cardBackground};

            .card-header {
                color: ${colors.greys.strong};
                font-size: 1.1em;
                font-weight: 500;
            }

            .controls {
                padding-top: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .device-control-label {
                    ${'' /* margin-top: 6px; */}
                }
            }

            .general-card-label {
                color: ${colors.homePageLabel};
                font-weight: 600;
            }

            .divider {
                border: 0.5px solid ${colors.greys.primary};
                width: 210px;
            }

            .grow-info-container {
                margin: 18px 0 12px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .total-grow-days {
                    ${'' /* margin-bottom: 12px; */}
                }

                .flowering-info {
                    margin-top: 12px;
                }

                .growing-number {
                    ${'' /* font-weight: 600; */}
                    ${'' /* font-size: 1.1em; */}
                }
            }

            .device-select-container {
                ${'' /* padding: 8px 0 12px; */}
            }
        }

        .bottom-card {
            margin-bottom: 1em;
        }
    }
`;

const Home = () => {
    const devices = useSelector(state => state.devices);
    const { peripherals, clientConnectionAlert, canopyMateConnectionAlert } = useSelector(state => state.access);
    const currentGrows = useSelector(state => state.grows.current);

    const dispatch = useDispatch();

    const [growStartLoading, setGrowStartLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const startGrow = async (currentDevice) => {
        const db = fire.firestore();
        const userID = fire.auth().currentUser.uid;
    
        const growRef = db.collection('grows').doc();
        const userRef = db.collection('users').doc(userID);
        const deviceRef = db.collection('devices').doc(currentDevice);

        setGrowStartLoading(true);
    
        await db.runTransaction(async transaction => {
            await transaction.get(growRef).then(() => {
                transaction.set(growRef, {
                    deviceID: currentDevice,
                    userID,
                    startDate: moment().format(MOMENT_DATE_FORMAT),
                    flowering: false,
                    active: true
                })
                .update(userRef, {
                    'grows.current': firebase.firestore.FieldValue.arrayUnion(growRef.id)
                })
                .update(deviceRef, {
                    currentGrow: growRef.id
                });
            })
        })
        .then(() => {
            setTimeout(() => {
                setGrowStartLoading(false);
                logAnalyticsEvent('growStarted');
            }, 1000)
        })
        .catch(() => {
            setGrowStartLoading(false);
            setAlertMessage("Unable to start grow. Please check your connection and try again.");
            setOpenAlert(true);
        });
    }

    const { currentDevice } = devices;

    const deviceStateLoaded = currentDevice && devices[currentDevice] && Object.keys(devices[currentDevice]).length > 0;
    const growsLoaded = Object.keys(currentGrows).length > 0;

    let currentDeviceLS = localStorage.getItem(CURRENT_DEVICE);
    currentDeviceLS = (currentDeviceLS === 'undefined' ? undefined : currentDeviceLS);

    return (
        <>
            <HomeContainer>
                <Header />
                {(currentDeviceLS && !deviceStateLoaded) && <div className="loading-spinner">
                    <Spinner size={40} />
                </div>}
                {(!currentDeviceLS) && <div className="peripherals">
                    {/* Home - General (No devices owned) */}
                    <Card className="peripheral-card">
                        <div className="controls">
                            <div className="general-card-label">
                                Get Started:
                            </div>
                            <div className="device-select-container">
                                <DeviceSelect />
                            </div>
                        </div>
                    </Card>
                </div>}
                {deviceStateLoaded && <div className="peripherals">
                    {/* Home - General (Devices owned) */}
                    <Card className="peripheral-card">
                        <div className="card-header device-name">
                            Canopy Mate
                        </div>
                        <div className="controls">
                            <div className="general-card-label device-control-label">
                                Currently controlling:
                            </div>
                            <div className="device-select-container">
                                <DeviceSelect />
                            </div>
                            <div className="divider"></div>
                            <div className="general-card-label">
                                {!devices[currentDevice].currentGrow &&
                                    <CMButton
                                        text="Start new grow"
                                        onClick={() => startGrow(currentDevice)}
                                        customStyle={{ margin: '15px 0 9px' }}
                                        loading={growStartLoading}
                                    />
                                }
                                {devices[currentDevice].currentGrow && growsLoaded && 
                                    <>{(() => {
                                        const grow = currentGrows[devices[currentDevice].currentGrow];
                                        return grow && (
                                            <div className="grow-info-container">
                                                <div className="total-grow-days">
                                                    Total days growing: <span className="growing-number">{moment().diff(moment(grow.startDate), 'days') + 1}</span>
                                                </div>
                                                {grow.flowering && 
                                                    <>{(() => {
                                                        const daysFlowering = moment().diff(moment(grow.floweringStartDate), 'days') + 1;
                                                        const weekFlowering = Math.ceil(daysFlowering / 7);
                                                        return (
                                                            <div className="flowering-info">
                                                                {"Days flowering: "}
                                                                <span className="growing-number">{daysFlowering}</span> 
                                                                {" (Week "} <span className="growing-number">{weekFlowering}</span>)
                                                            </div>
                                                        )
                                                    })()}</>
                                                }
                                            </div>
                                        )
                                    })()}</>
                                }
                            </div>
                        </div>
                    </Card>
                    {/* Fan */}
                    <Card className="peripheral-card">
                        <div className="card-header">
                            Fans
                        </div>
                        <div className="controls">
                            <div style={{ paddingBottom: 6 }}>
                                <FanSwitch 
                                    fanState={devices[currentDevice].fan_state}
                                    field={"fan_state"}
                                    label={"Ventilation"}
                                />
                            </div>
                            {devices[currentDevice].using_internal_fan && <FanSwitch 
                                fanState={devices[currentDevice].fan2_state}
                                field={"fan2_state"}
                                label={"Circulation"}
                            />}
                        </div>
                    </Card>
                    {/* Light */}
                    <Card className="peripheral-card">
                        <div className="card-header">
                            LED Grow Light
                        </div>
                        <div className="controls">
                            <LightSettings
                                deviceSettings={devices[currentDevice]}
                                hasGrowFlowering={devices[currentDevice].currentGrow &&
                                    growsLoaded &&
                                    currentGrows[devices[currentDevice].currentGrow] &&
                                    currentGrows[devices[currentDevice].currentGrow].flowering}
                            />
                        </div>
                    </Card>
                    {/* Water Pump */}
                    <Card className="peripheral-card bottom-card">
                        <div className="card-header">
                            Irrigation
                        </div>
                        <div className="controls">
                            <PumpSettings deviceSettings={devices[currentDevice]} />
                        </div>
                    </Card>
                </div>}
            </HomeContainer>
            <Backdrop style={{ zIndex: 22 }} open={!peripherals}>
                <Spinner size={40} color="inherit" />
            </Backdrop>
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
            <CMAlert
                open={clientConnectionAlert}
                onClose={() => dispatch(closeClientConnectionAlert())}
                headMessage="Connection issue"
                mainMessage="Please check your device's network settings and try again"
            />
            <CMAlert
                open={canopyMateConnectionAlert}
                onClose={() => dispatch(closeCanopyMateConnectionAlert())}
                headMessage="Connection issue"
                mainMessage="Please ensure your Canopy Mate is connected to your home network and try again"
            />
        </>
    );
};

export default Home;
