import React, { useState } from 'react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { appHeader, hamburger } from '../../constants/colors';
import Navigation from './Navigation';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../constants/routes';

const StyledHeader = styled.div`
    .appbar {
        background-color: ${appHeader};
    }

    .toolbar {
        display: flex;
        justify-content: space-between;

        .MuiIconButton-root {
            color: ${hamburger};
        }
    }

    .logo {
        width: 52px;
        height: 52px;
    }
`;

const ElevationScroll = (props) => {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const Header = () => {
    const [showNav, setShowNav] = useState(false);

    const history = useHistory();

    const returnHome = () => {
        if (!(history.location.pathname === ROUTES.HOME)) {
            history.push(ROUTES.HOME);
        }
    }

    return (
        <>
            <StyledHeader>
                <ElevationScroll>
                    <AppBar className="appbar">
                        <Toolbar className="toolbar">
                            <IconButton
                                edge="start"
                                onClick={() => setShowNav(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <img
                                className="logo"
                                onClick={returnHome}
                                src="images/logos/logo-transparent-strong-green.png"
                                alt="logo"
                            />
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
            </StyledHeader>
            <Toolbar />
            <Navigation 
                open={showNav}
                onClose={() => setShowNav(false)}
            />
        </>
    );
}

export default Header;
