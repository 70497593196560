import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CMButton from './CMButton';
import colors from '../../constants/colors';

const StyledDialog = styled(Dialog)`
    .content-container {
        padding: 12px;
        text-align: center;
        max-width: 300px;
        box-sizing: border-box;

        .confirm-message {
            font-weight: 600;
            padding: 12px 0;
            color: ${colors.greys.darkest};
            font-size: 1.1em;
        }

        .sub-message {
            padding-bottom: 12px;
            font-size: 0.9em;
            color: ${colors.greys.dark};
            font-weight: 500;
        }
    }
`;

const ConfirmAction = ({ open, onClose, confirmMessage, subMessage, confirmButtonText, onConfirm, loading, confirmButtonCustomStyle, spinnerColor }) => {
    return (
        <>
            <StyledDialog open={open || false} onClose={onClose}>
                <div className="content-container">
                    <div className="confirm-message">
                        {confirmMessage || "Are you sure?"}
                    </div>
                    {subMessage && <div className="sub-message">
                        {subMessage}
                    </div>}
                    <div className="action-container">
                        <CMButton
                            text="Cancel"
                            onClick={onClose}
                            customStyle={{ backgroundColor: colors.greys.strong }}
                        />
                        <CMButton
                            text={confirmButtonText || "Yes"}
                            onClick={onConfirm}
                            loading={loading}
                            customStyle={confirmButtonCustomStyle}
                            spinnerColor={spinnerColor}
                        />
                    </div>
                </div>
            </StyledDialog>
        </>
    )
}

export default ConfirmAction;
