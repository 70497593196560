import fire from './fire';
import {
    setCurrentDevice,
    addDevice,
    removeDevice,
    writeLastHeartBeat,
    addGrow,
    removeGrow
} from './config/actions';
import { CURRENT_DEVICE, HAS_CURRENT_GROWS } from './constants/storageKeys';
import { store } from './index'

/**
 * firestoreToRedux
 * 
 * Flushes user data from firestore into redux
 * 
 * Creates listeners to user, device, and grow nodes
 * 
 * @param {string} userUID user.uid from firebase auth user object
 * @param {function} dispatch returned from useDispatch redux hook
 */
const firestoreToRedux = (userUID, dispatch) => {
    let growListeners = [];
    let deviceListeners = [];

    fire.firestore().collection('users').doc(userUID).onSnapshot(doc => {
        if (!doc.exists) return;
        const { devices, grows } = doc.data();
        const storeState = store.getState();

        //
        //  Grows
        //
        if (grows && grows.current && grows.past) {
            let listener;

            // Reset listeners each refresh
            if (growListeners.length) {
                growListeners.forEach(listener => listener());
                growListeners = [];
            }

            if (grows.current.length) {
                localStorage.setItem(HAS_CURRENT_GROWS, 'true');
            } else {
                localStorage.removeItem(HAS_CURRENT_GROWS);
            }

            // Update local and externally connnected clients
            const { current: currentGrows, past: pastGrows } = storeState.grows;
            const currentGrowIDsInStore = Object.keys(currentGrows);
            const pastGrowIDsInStore = Object.keys(pastGrows);
    
            currentGrowIDsInStore.forEach(growID => {
                if (!grows.current.includes(growID)) {
                    dispatch(removeGrow(growID, 'current'));
                };
            });
    
            pastGrowIDsInStore.forEach(growID => {
                if (!grows.past.includes(growID)) {
                    dispatch(removeGrow(growID, 'past'));
                };
            });

            // Setup listeners to current grow data
            grows.current.forEach(growID => {
                listener = fire.firestore().collection('grows').doc(growID).onSnapshot(growDoc => {
                    const growData = growDoc.data();
                    dispatch(addGrow(growID, growData, 'current'));
                });
                growListeners.push(listener);
            });

            // Get past grow data once, no listeners
            grows.past.forEach(growID => {
                fire.firestore().collection('grows').doc(growID).get().then(growDoc => {
                    const growData = growDoc.data();
                    dispatch(addGrow(growID, growData, 'past'));
                });
            });
        }
        //
        // End Grows
        //

        //
        // Devices
        //
        
        // This just flushes localStorage back into redux and resets currentDevice pointer if user just deleted
        const currentDevice = localStorage.getItem(CURRENT_DEVICE);
        if (currentDevice && devices.includes(currentDevice)) {
            dispatch(setCurrentDevice(currentDevice));
        } else {
            dispatch(removeDevice(currentDevice));
            dispatch(setCurrentDevice(devices[0]));
        }

        // This updates other connected clients
        const { currentDevice: cd, ...usersDevicesInStore } = storeState.devices;
        const deviceIDsInStore = Object.keys(usersDevicesInStore);

        deviceIDsInStore.forEach(deviceID => {
            if (!devices.includes(deviceID)) {
                dispatch(removeDevice(deviceID));
            };
        });

        // Reset listeners on refresh
        if (deviceListeners.length) {
            deviceListeners.forEach(listener => listener());
            deviceListeners = [];
        }

        // This gets device connection info and sets up listeners
        devices.forEach(device => {
            let listener;
            
            getDeviceLastHeartbeat(device, dispatch);
            
            listener = fire.firestore().collection('devices').doc(device).onSnapshot(deviceDoc => {
                const deviceConfig = deviceDoc.data();
                dispatch(addDevice(device, deviceConfig));
            });

            deviceListeners.push(listener);
        });
        //
        // End Devices
        //
    });
}

// Track state of device call to cloud function to prevent double calls
let heartBeatFuncCallCount = {};
const secondsSinceHeartbeatFunc = fire.functions().httpsCallable('secondsSinceHeartbeat');

const getDeviceLastHeartbeat = (deviceID, dispatch) => {

    const secondsSinceHeartbeat = async () => {
        if (!heartBeatFuncCallCount[deviceID]) {
            heartBeatFuncCallCount[deviceID] = true;
            await secondsSinceHeartbeatFunc({ deviceID }).then(result => {
                dispatch(writeLastHeartBeat(deviceID, result.data));
            });
        }
    }

    secondsSinceHeartbeat();
}

export default firestoreToRedux;
