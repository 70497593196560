import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCOLkgd9TvSxgBb06VVIDqJlghq1REKSAo",
    authDomain: "canopymate-alpha.firebaseapp.com",
    databaseURL: "https://canopymate-alpha.firebaseio.com",
    projectId: "canopymate-alpha",
    storageBucket: "canopymate-alpha.appspot.com",
    messagingSenderId: "1036509014006",
    appId: "1:1036509014006:web:0a0f7cffe92e0b65a7c9d7",
    measurementId: "G-9YNXCBNH5R"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
