import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import CMButton from './CMButton';
import colors from '../../constants/colors';

const StyledDialog = styled(Dialog)`
    .content-container {
        padding: 12px;
        text-align: center;
        max-width: 300px;
        min-width: 225px;
        box-sizing: border-box;

        .alert-message {
            font-weight: 600;
            padding: 12px 0;
            color: ${colors.greys.darkest};
            font-size: 1.1em;
        }

        .main-message {
            padding-bottom: 12px;
            font-size: 0.9em;
            color: ${colors.greys.dark};
            font-weight: 500;
        }
    }
`;

const CMAlert = ({ open, onClose, headMessage, mainMessage }) => {
    return (
        <>
            <StyledDialog
                open={open || false}
                onClose={onClose}
                transitionDuration={400}
            >
                {open && <div className="content-container">
                    <div className="alert-message">
                        {headMessage || "Something went wrong!"}
                    </div>
                    <div className="main-message">
                        {mainMessage}
                    </div>
                    <div className="action-container">
                        <CMButton
                            text="OK"
                            onClick={onClose}
                        />
                    </div>
                </div>}
            </StyledDialog>
        </>
    )
}

export default CMAlert;
