import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { spinnerColor } from '../../constants/colors';

const Container = styled.div`
    .MuiCircularProgress-colorPrimary {
        color: ${props => props.color || spinnerColor};
    }
`;

const Spinner = ({ size, color }) => {
    return (
        <Container color={color}>
            <CircularProgress
                size={size}
                thickness={4.8}
            />
        </Container>
    )
}

export default Spinner;
