import React from 'react'
import {
    TimeOnPicker,
    DurationSlider,
} from '../peripherals';

const LightSettings = ({ deviceSettings, hasGrowFlowering }) => {

    const lightDuration = deviceSettings.light_duration;
    const lightDurationHours = lightDuration / 3600;

    let sliderLabel;

    switch(lightDurationHours) {
        case 0:
            sliderLabel = "Light is off";
            break;
        case 1:
            sliderLabel = "On for 1 hour";
            break;
        case 24:
            sliderLabel = "Light is always on";
            break;
        default:
            sliderLabel = `On for ${lightDurationHours} hours`;
    }

    return (
        <>
            {(lightDurationHours > 0 && lightDurationHours < 24) && <TimeOnPicker 
                secondsIntoUtcDay={deviceSettings.light_on}
                label={"Turns on at"}
                field={"light_on"}
            />}
            <DurationSlider
                durationSeconds={lightDuration}
                label={sliderLabel}
                field={"light_duration"}
                step={1}
                min={0}
                max={24}
                stepSeconds={3600}
                hasGrowFlowering={hasGrowFlowering}
            />
        </>
    );
};

export default LightSettings;
