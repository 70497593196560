import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import fire from './fire';
import Home from './components/home/Home';
import Devices from './components/devices/Devices';
import Login from './components/auth/Login';
import Grows from './components/grows/Grows';
import { LOGGED_IN } from './constants/storageKeys';
import firestoreToRedux from './firestoreToRedux';
import { Switch, Route } from 'react-router-dom';
import ROUTES from './constants/routes';
import Splash from './Splash';

const App = () => {
    const [user, setUser] = useState(null);

    const dispatch = useDispatch();

    fire.auth().onAuthStateChanged(user => {
        if (user) {
            localStorage.setItem(LOGGED_IN, 'true');
            firestoreToRedux(user.uid, dispatch);
        } else {
            localStorage.removeItem(LOGGED_IN);
        }
        setUser(user);
    });

    if (user) {
        return (
            <Switch>
                <Route path={ROUTES.DEVICES}>
                    <Devices />
                </Route>
                <Route path={ROUTES.GROWS}>
                    <Grows />
                </Route>
                <Route path={ROUTES.HOME}>
                    <Home />
                </Route>
            </Switch>
        )
    } else {
        if (localStorage.getItem(LOGGED_IN)) return <Splash />;
        return <Login />;
    }
};

export default App;
