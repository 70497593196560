import React, { useState } from 'react';
import styled from 'styled-components';
import fire from '../../fire';
import colors, { appBackground } from '../../constants/colors';
import PasswordRecoveryModal from './PasswordRecoveryModal';
import CMButton from '../common/CMButton';
import CMAlert from '../common/CMAlert';

const LoginForm = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${appBackground};
    font-family: "Montserrat";

    .title {
        margin-bottom: 18px;
        font-size: 1.5em;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: ${colors.blues.dark};

        .logo {
            width: 40px;
            height: 40px;
            margin-bottom: 3px;
        }
    }

    .form-container {
        border: solid 1px lightgrey;
        border-radius: 5px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${colors.white};

        .form-header {
            font-weight: 600;
            margin-bottom: 10px;
            color: ${colors.blues.dark};
            font-size: 1.1em;
        }

        .input-field {
            display: flex;
            flex-direction: column;

            .text-input {
                font-family: "Montserrat";
                font-size: 0.9em;
                border-radius: 4px;
                padding: 3px;
                border: solid 1px ${colors.greys.strong};
            }

            .label-text {
                font-weight: 500;
                margin-bottom: 3px;
                color: ${colors.greys.dark};
            }
        }

        .email-input {
            margin-bottom: 8px;
        }

        .submit-container {
            margin-top: 16px;
        }
    }

    .recover-button {
        margin-top: 24px;
    }
`;

const Login = () => {
    const [login, setLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = async () => {
        setLoading(true);

        if (login) {
            await fire.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setAlertMessage("Please check the email and password you entered");
                setOpenAlert(true);
            });
        } else {
            await fire.auth().createUserWithEmailAndPassword(email, password)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                switch(error.code) {
                    case 'auth/email-already-in-use':
                        setAlertMessage("An account has already been created with this email");
                        break;
                    case 'auth/weak-password':
                        setAlertMessage("Please use a stronger password");
                        break;
                    case 'auth/invalid-email':
                        setAlertMessage("Please check your email formatting");
                        break;
                    default:
                        setAlertMessage("Unable to register, please try again. Contact support if issue persists.");
                        break;
                }
                setOpenAlert(true);
            });
        }
    }

    const displayText = login ? "Login" : "Register";

    return (
        <>
            <LoginForm>
                <div className="title">
                    <div>Canopy</div>
                    <img
                        className="logo"
                        src="images/logos/logo-green-round-small-auth-lightblue.png"
                        alt="logo"
                    />
                    <div>Mate</div>
                </div>
                <div className="form-container">
                    <div className="form-header">
                        {displayText}
                    </div>
                    <label className="input-field email-input">
                        <span className="label-text">Email:</span>
                        <input
                            className="text-input"
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </label>
                    <label className="input-field">
                        <span className="label-text">Password:</span>
                        <input
                            className="text-input"
                            type="password" 
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </label>
                    <CMButton
                        text={displayText}
                        onClick={handleSubmit}
                        loading={loading}
                        customStyle={{ margin: '18px 0 6px' }}
                    />
                </div>
                {login && <CMButton
                    text="Forgot password?"
                    onClick={() => setModalOpen(true)}
                    disabled={loading}
                    customStyle={{
                        margin: '24px 0 0',
                        backgroundColor: colors.greys.strong
                    }}
                />}
                <CMButton
                    text={login ? "Sign Up" : "Already Have Account?"}
                    onClick={() => {
                        setLogin(!login);
                        setEmail('');
                        setPassword('');
                    }}
                    disabled={loading}
                    customStyle={{
                        margin: '24px 0 0',
                        backgroundColor: colors.greys.strong
                    }}
                />
            </LoginForm>
            <PasswordRecoveryModal open={modalOpen} handleClose={() => setModalOpen(false)} />
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
        </>
    )
};

export default Login;
