import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import colors, { journalTheme } from '../../constants/colors';
import moment from 'moment';
import CMButton from '../common/CMButton';
import fire from '../../fire';
import firebase from 'firebase/app';
import ConfirmAction from '../common/ConfirmAction';
import CMAlert from '../common/CMAlert';
import Journal from './Journal';

const Container = styled.div`
    padding: 12px;

    .lead-text {
        color: ${colors.greys.strong};
        font-weight: 600;
        font-size: 1.1em;
        text-align: center;
    }

    .past-grow-card {
        padding: 12px;
        margin-bottom: 12px;

        .font-container {
            font-weight: 600;
            color: ${colors.greys.dark};
            margin-bottom: 12px;

            .font-container-label {
                color: ${colors.greys.strong}
            }
        }

        .past-grow-device-name {
        }

        .journal-container {
            padding: 6px 0 18px;
        }
    }

    .past-grow-card:last-child {
        margin-bottom: 0;
    }
`;

const Past = () => {
    const grows = useSelector(state => state.grows.past);
    const devices = useSelector(state => state.devices);

    const [deleteGrowLoading, setDeleteGrowLoading] = useState({});
    const [openConfirmDeleteGrow, setOpenConfirmDeleteGrow] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openJournal, setOpenJournal] = useState({});

    const growIDs = Object.keys(grows);
    const growsLoaded = growIDs.length > 0;

    if (growsLoaded) {
        growIDs.sort((a, b) => {
            const aEndDate = grows[a].endDate;
            const bEndDate = grows[b].endDate;
            
            if (aEndDate < bEndDate) {
                return 1;
            }

            if (aEndDate > bEndDate) {
                return -1;
            }

            return 0;
        });
    }

    const deleteGrow = async (growID) => {
        const db = fire.firestore();
        const userID = fire.auth().currentUser.uid;

        const deleteJournalEntries = firebase.functions().httpsCallable('deleteJournalEntries');
    
        const growRef = db.collection('grows').doc(growID);
        const userRef = db.collection('users').doc(userID);

        setDeleteGrowLoading(gl => ({ ...gl, [growID]: true }));
    
        await db.runTransaction(async transaction => {
            await transaction.get(growRef).then(() => {
                transaction.update(userRef, {
                    'grows.past': firebase.firestore.FieldValue.arrayRemove(growID)
                })
                .delete(growRef)
            })
        })
        .then(() => {
            setDeleteGrowLoading(gl => ({ ...gl, [growID]: false }));

            deleteJournalEntries({ growID })
            .then(() => {
                console.log('deleted journal');
            })
            .catch((error) => {
                console.warn(error);
            });
        })
        .catch(() => {
            setDeleteGrowLoading(gl => ({ ...gl, [growID]: false }))
            setAlertMessage("Unable to delete grow. Please check your connection and try again.");
            setOpenAlert(true);
        });
    }

    return (
        <>
            <Container>
                {!growsLoaded && <div className="lead-text">
                    No past grows
                </div>}
                {growsLoaded && growIDs.map((growID, idx) => {
                    const { deviceID, endDate, flowering, floweringStartDate, startDate, totalGrowDays } = grows[growID];
                    return (
                        <Card key={idx} className="past-grow-card">
                            <div className="past-grow-device-name font-container">
                                <span className="font-container-label">{"Grown in: "}</span>
                                {deviceID && (devices[deviceID] ? devices[deviceID].name : "(No longer owned)")}
                            </div>
                            <div className="font-container">
                                <span className="font-container-label">{"Started: "}</span>
                                {moment(startDate).format("MMMM Do YYYY")}
                            </div>
                            <div className="font-container">
                                <span className="font-container-label">{"Ended: "}</span>
                                {moment(endDate).format("MMMM Do YYYY")}
                            </div>
                            <div className="font-container">
                                <span className="font-container-label">{"Total days growing: "}</span>
                                {totalGrowDays}
                            </div>
                            {flowering && <div className="font-container">
                                <span className="font-container-label">{"Days flowering: "}</span>
                                {moment(endDate).diff(moment(floweringStartDate), 'days')}
                            </div>}
                            <div className="journal-container">
                                <CMButton
                                    text="Journal"
                                    onClick={() => setOpenJournal({ [growID]: true })}
                                    customStyle={{
                                        margin: 0,
                                        backgroundColor: journalTheme,
                                        width: 112
                                    }}
                                />
                                <Journal
                                    open={openJournal[growID]}
                                    onClose={() => setOpenJournal({})}
                                    growID={growID}
                                />
                            </div>
                            <div className="delete-grow-container">
                                <CMButton
                                    text="Delete grow"
                                    onClick={() => setOpenConfirmDeleteGrow(dg => ({ ...dg, [growID]: true }))}
                                    customStyle={{
                                        margin: 0,
                                        backgroundColor: colors.greys.strong,
                                        width: 112
                                    }}
                                />
                                <ConfirmAction
                                    open={openConfirmDeleteGrow[growID]}
                                    onClose={() => setOpenConfirmDeleteGrow(dg => ({ ...dg, [growID]: false }))}
                                    onConfirm={() => deleteGrow(growID)}
                                    loading={deleteGrowLoading[growID]}
                                    subMessage="This will delete all your journal entries as well!"
                                />
                            </div>
                        </Card>
                    )
                })}
            </Container>
            <CMAlert
                open={openAlert}
                onClose={() => {
                    setOpenAlert(false);
                    setAlertMessage('');
                }}
                mainMessage={alertMessage}
            />
        </>
    );
}

export default Past;
